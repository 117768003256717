import React, { useEffect, useState } from 'react';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { useDataModel } from '@thd-nucleus/data-sources';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { ComponentPlaceholder } from '@thd-olt-component-react/loading-placeholder';
import { useTheme } from '@thd-olt-component-react/theme-provider';
import { useLifeCycleEventBusLifeCycle } from '@thd-olt-functional/utils';
import { MobileContent } from './components/Content/MobileContent';
import {
  defaultThemeProperties,
  productOverviewDefaultProps,
  productOverviewPropTypes,
  themeProperties,
} from './helpers';
import { RichContent } from './components/RichContent/RichContent';
import { dataModel } from './dataModel';

export const ProductOverviewMobileComponent = (props) => {
  const {
    itemId,
    showRichContentsOnly,
    hideRichContents,
    showReturnMessage,
    showPla,
  } = props;

  const { configuredItemId } = useLifeCycleEventBusLifeCycle('configurator.stock_product_chosen_lifecycle_event');

  if (showRichContentsOnly) {
    return (
      <RichContent itemId={itemId} />
    );
  }

  return (
    <div
      data-section="product-overview"
      data-testid="product-overview-mobile"
    >
      <MobileContent
        itemId={configuredItemId || itemId}
        showRichContentsOnly={showRichContentsOnly}
        hideRichContents={hideRichContents}
        showReturnMessage={showReturnMessage}
        showPla={showPla}
      />
    </div>
  );
};

ProductOverviewMobileComponent.displayName = 'ProductOverviewMobileComponent';

ProductOverviewMobileComponent.dataModel = dataModel;

ProductOverviewMobileComponent.themeProps = themeProperties;

ProductOverviewMobileComponent.defaultThemeProps = defaultThemeProperties;

ProductOverviewMobileComponent.propTypes = productOverviewPropTypes;

ProductOverviewMobileComponent.defaultProps = productOverviewDefaultProps;

const HydratedComponent = withHydrator({
  delay: 1500,
  id: 'product-section-overview',
  placeholder: (
    <ComponentPlaceholder
      height={193}
      rows={4}
      color="#E0E0E0"
      showLoadingAnimation
    />
  ),
  scrollBuffer: 750,
}, ProductOverviewMobileComponent);

const DynamicComponent = withDynamicComponent(HydratedComponent);
const ProductOverviewMobile = withErrorBoundary(DynamicComponent);

ProductOverviewMobile.displayName = 'ProductOverviewMobile';

ProductOverviewMobile.dataModel = dataModel;

ProductOverviewMobile.propTypes = productOverviewPropTypes;

ProductOverviewMobile.defaultProps = productOverviewDefaultProps;

ProductOverviewMobile.themeProps = themeProperties;

ProductOverviewMobile.defaultThemeProps = defaultThemeProperties;

ProductOverviewMobile.dataModel = dataModel;

export { ProductOverviewMobile };
