import { CUSTOM_PRICE_VSO } from '../constants';

export function productBundleData({
  originalPrice,
  discount,
  percent,
  specialBuy,
  specialPrice,
  someSkusHaveZeroPrice,
  maMinAdvertisedPriceExists,
  partnerProductId,
  pipfiguratorType
}
) {
  const isStartingAtPipfiguratorType = pipfiguratorType === CUSTOM_PRICE_VSO;
  return {
    configuratorPricing: {
      itemId: partnerProductId,
      identifiers: {
        itemId: partnerProductId,
      },
      pricing: {
        alternate: {
          bulk: {
            value: null,
            thresholdQuantity: null,
          },
          unit: {
            unitsPerCase: null,
            caseUnitOfMeasure: null,
            unitsOriginalPrice: null,
            value: null,
          },
        },
        alternatePriceDisplay: false,
        mapAboveOriginalPrice: maMinAdvertisedPriceExists,
        message: isStartingAtPipfiguratorType ? 'Starting at' : null,
        original: originalPrice,
        preferredPriceFlag: null,
        promotion: {
          dates: {
            start: null,
            end: null,
          },
          dollarOff: discount,
          percentageOff: percent,
          savingsCenter: specialBuy,
          savingsCenterPromos: null,
          specialBuySavings: null,
          specialBuyDollarOff: null,
          specialBuyPercentageOff: null,
          type: 'DISCOUNT',
        },
        specialBuy: null,
        unitOfMeasure: 'bundle',
        value: specialPrice,
        someSkusHaveZeroPrice,
      },
      info: {
        sskMax: null,
        sskMin: null,
      },
      disableRangePricing: !isStartingAtPipfiguratorType,
      omitDetails: false,
      showStartingAt: isStartingAtPipfiguratorType,
    },
  };
}

export const mockProductsFulfillmentData = [
  {
    __typename: 'BaseProduct',
    itemId: '310554018',
    fulfillment: {
      __typename: 'Fulfillment',
      anchorStoreStatusType: 'ACTIVE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'AK,GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'AK,GU,HI,PR,VI',
      fulfillmentOptions: [
        {
          __typename: 'FulfillmentOption',
          fulfillable: true,
          messages: ['6586 -InStock'],
          services: [
            {
              __typename: 'Service',
              type: 'bopis',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: null,
              deliveryTimeline: null,
              freeDeliveryThreshold: null,
              totalCharge: null,
              locations: [
                {
                  __typename: 'Location',
                  type: 'store',
                  isAnchor: true,
                  locationId: '6586',
                  storeName: 'Spring Cypress',
                  storePhone: '(281)304-1956',
                  state: 'TX',
                  distance: null,
                  inventory: {
                    __typename: 'Inventory',
                    quantity: 9,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                  },
                },
              ],
            },
          ],
          type: 'pickup',
        },
      ],
    },
  },
  {
    __typename: 'BaseProduct',
    itemId: '203164241',
    fulfillment: {
      __typename: 'Fulfillment',
      anchorStoreStatusType: 'CLEARANCE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'AK,GU,HI,PR,VI',
      fulfillmentOptions: [
        {
          __typename: 'FulfillmentOption',
          fulfillable: true,
          messages: ['InStock'],
          services: [
            {
              __typename: 'Service',
              type: 'boss',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: {
                __typename: 'DeliveryDate',
                endDate: '2024-02-26',
                startDate: '2024-02-23',
              },
              deliveryTimeline: null,
              freeDeliveryThreshold: null,
              totalCharge: 0,
              locations: [
                {
                  __typename: 'Location',
                  type: 'online',
                  isAnchor: true,
                  locationId: '6586',
                  storeName: 'Spring Cypress',
                  storePhone: null,
                  state: 'TX',
                  distance: null,
                  inventory: {
                    __typename: 'Inventory',
                    quantity: 474,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                  },
                },
              ],
            },
          ],
          type: 'pickup',
        },
      ],
    },
  },
  {
    __typename: 'BaseProduct',
    itemId: '302757312',
    fulfillment: {
      __typename: 'Fulfillment',
      anchorStoreStatusType: 'ACTIVE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'AK,GU,HI,PR,VI',
      fulfillmentOptions: [
        {
          __typename: 'FulfillmentOption',
          fulfillable: true,
          messages: ['ShipToHome null'],
          services: [
            {
              __typename: 'Service',
              type: 'sth',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: {
                __typename: 'DeliveryDate',
                endDate: '2024-02-24',
                startDate: '2024-02-24',
              },
              deliveryTimeline: 'later',
              freeDeliveryThreshold: null,
              totalCharge: 0,
              locations: [
                {
                  __typename: 'Location',
                  type: 'online',
                  isAnchor: false,
                  locationId: '8119',
                  storeName: null,
                  storePhone: null,
                  state: null,
                  distance: null,
                  inventory: {
                    __typename: 'Inventory',
                    quantity: 474,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                  },
                },
              ],
            },
          ],
          type: 'delivery',
        },
      ],
    },
  },
];

export const mockProductsFulfillmentOptionMissingData = [
  {
    __typename: 'BaseProduct',
    itemId: '310554018',
    fulfillment: {
      __typename: 'Fulfillment',
      anchorStoreStatusType: 'ACTIVE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'AK,GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'AK,GU,HI,PR,VI',
      fulfillmentOptions: null,
    },
  },
  {
    __typename: 'BaseProduct',
    itemId: '203164241',
    fulfillment: {
      __typename: 'Fulfillment',
      anchorStoreStatusType: 'CLEARANCE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'AK,GU,HI,PR,VI',
      fulfillmentOptions: [
        {
          __typename: 'FulfillmentOption',
          fulfillable: true,
          messages: ['InStock'],
          services: [
            {
              __typename: 'Service',
              type: 'boss',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: {
                __typename: 'DeliveryDate',
                endDate: '2024-02-26',
                startDate: '2024-02-23',
              },
              deliveryTimeline: null,
              freeDeliveryThreshold: null,
              totalCharge: 0,
              locations: [
                {
                  __typename: 'Location',
                  type: 'online',
                  isAnchor: true,
                  locationId: '6586',
                  storeName: 'Spring Cypress',
                  storePhone: null,
                  state: 'TX',
                  distance: null,
                  inventory: {
                    __typename: 'Inventory',
                    quantity: 474,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                  },
                },
              ],
            },
          ],
          type: 'pickup',
        },
      ],
    },
  },
  {
    __typename: 'BaseProduct',
    itemId: '302757312',
    fulfillment: {
      __typename: 'Fulfillment',
      anchorStoreStatusType: 'ACTIVE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'AK,GU,HI,PR,VI',
      fulfillmentOptions: [
        {
          __typename: 'FulfillmentOption',
          fulfillable: true,
          messages: ['ShipToHome null'],
          services: [
            {
              __typename: 'Service',
              type: 'sth',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: {
                __typename: 'DeliveryDate',
                endDate: '2024-02-24',
                startDate: '2024-02-24',
              },
              deliveryTimeline: 'later',
              freeDeliveryThreshold: null,
              totalCharge: 0,
              locations: [
                {
                  __typename: 'Location',
                  type: 'online',
                  isAnchor: false,
                  locationId: '8119',
                  storeName: null,
                  storePhone: null,
                  state: null,
                  distance: null,
                  inventory: {
                    __typename: 'Inventory',
                    quantity: 474,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                  },
                },
              ],
            },
          ],
          type: 'delivery',
        },
      ],
    },
  },
];

export const mockProductsSthFulfillmentData = [
  {
    __typename: 'BaseProduct',
    itemId: '310554018',
    fulfillment: {
      __typename: 'Fulfillment',
      anchorStoreStatusType: 'ACTIVE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'AK,GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'AK,GU,HI,PR,VI',
      fulfillmentOptions: [
        {
          __typename: 'FulfillmentOption',
          fulfillable: true,
          messages: ['InStock'],
          services: [
            {
              __typename: 'Service',
              type: 'boss',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: {
                __typename: 'DeliveryDate',
                endDate: '2024-02-26',
                startDate: '2024-02-23',
              },
              deliveryTimeline: null,
              freeDeliveryThreshold: null,
              totalCharge: 0,
              locations: [
                {
                  __typename: 'Location',
                  type: 'online',
                  isAnchor: true,
                  locationId: '6586',
                  storeName: 'Spring Cypress',
                  storePhone: null,
                  state: 'TX',
                  distance: null,
                  inventory: {
                    __typename: 'Inventory',
                    quantity: 4,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                  },
                },
              ],
            },
          ],
          type: 'pickup',
        },
        {
          __typename: 'FulfillmentOption',
          fulfillable: true,
          messages: ['ShipToHome null'],
          services: [
            {
              __typename: 'Service',
              type: 'sth',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: {
                __typename: 'DeliveryDate',
                endDate: '2024-02-24',
                startDate: '2024-02-24',
              },
              deliveryTimeline: 'later',
              freeDeliveryThreshold: null,
              totalCharge: 0,
              locations: [
                {
                  __typename: 'Location',
                  type: 'online',
                  isAnchor: false,
                  locationId: '8119',
                  storeName: null,
                  storePhone: null,
                  state: null,
                  distance: null,
                  inventory: {
                    __typename: 'Inventory',
                    quantity: 50,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                  },
                },
              ],
            },
          ],
          type: 'delivery',
        },
      ],
    },
    info: {
      quantityLimit: 2
    }
  },
  {
    __typename: 'BaseProduct',
    itemId: '203164241',
    fulfillment: {
      __typename: 'Fulfillment',
      anchorStoreStatusType: 'CLEARANCE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'AK,GU,HI,PR,VI',
      fulfillmentOptions: [

        {
          __typename: 'FulfillmentOption',
          fulfillable: true,
          messages: ['ShipToHome null'],
          services: [
            {
              __typename: 'Service',
              type: 'sth',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: {
                __typename: 'DeliveryDate',
                endDate: '2024-02-24',
                startDate: '2024-02-24',
              },
              deliveryTimeline: 'later',
              freeDeliveryThreshold: null,
              totalCharge: 0,
              locations: [
                {
                  __typename: 'Location',
                  type: 'online',
                  isAnchor: false,
                  locationId: '8119',
                  storeName: null,
                  storePhone: null,
                  state: null,
                  distance: null,
                  inventory: {
                    __typename: 'Inventory',
                    quantity: 15,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                  },
                },
              ],
            },
          ],
          type: 'delivery',
        },
      ],
    },
    info: {
      quantityLimit: 0
    }
  },
  {
    __typename: 'BaseProduct',
    itemId: '302757312',
    fulfillment: {
      __typename: 'Fulfillment',
      anchorStoreStatusType: 'ACTIVE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'AK,GU,HI,PR,VI',
      fulfillmentOptions: [
        {
          __typename: 'FulfillmentOption',
          fulfillable: true,
          messages: ['ShipToHome null'],
          services: [
            {
              __typename: 'Service',
              type: 'sth',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: {
                __typename: 'DeliveryDate',
                endDate: '2024-02-24',
                startDate: '2024-02-24',
              },
              deliveryTimeline: 'later',
              freeDeliveryThreshold: null,
              totalCharge: 0,
              locations: [
                {
                  __typename: 'Location',
                  type: 'online',
                  isAnchor: false,
                  locationId: '8119',
                  storeName: null,
                  storePhone: null,
                  state: null,
                  distance: null,
                  inventory: {
                    __typename: 'Inventory',
                    quantity: 474,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                  },
                },
              ],
            },
          ],
          type: 'delivery',
        },
      ],
    },
    info: {
      quantityLimit: 0
    }
  },
];

export const mockedcartItems = [{
  itemId: '320752334',
  fulfillmentLocation: '77429',
  fulfillmentMethod: 'ShipToHome',
  quantity: 1,
  storeId: '6586',
  storeSkuNumber: '1007815143',
  unitPrice: 444.96,
  zipCode: '77429'
}, {
  itemId: '317449611',
  fulfillmentLocation: '77429',
  fulfillmentMethod: 'ShipToHome',
  quantity: 1,
  storeId: '6586',
  storeSkuNumber: '1006484590',
  unitPrice: 499.99,
  zipCode: '77429'
}];

export const mockedCartOptions = {
  alterBrowserHistory: true,
  misship: true,
  channel: 'desktop',
  configurableItems: mockedcartItems,
  fulfillmentMethod: 'ShipToHome',
  storeId: '131',
  storeZip: 77343,
  zipCode: 77343,
  bypassBss: true,
  bss: true,
  directCheckout: true,
  hasConfigurableItems: false,
  component: 'add both'
};

export const mockedOOSproducts = [
  {
    itemId: '319376784',
    fulfillment: {
      anchorStoreStatusType: 'ACTIVE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'GU,PR,VI',
      fulfillmentOptions: [
        {
          fulfillable: true,
          messages: [
            '6586 -InStock'
          ],
          services: [
            {
              type: 'bopis',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: null,
              deliveryTimeline: null,
              freeDeliveryThreshold: null,
              totalCharge: null,
              locations: [
                {
                  type: 'store',
                  isAnchor: true,
                  locationId: '6586',
                  storeName: 'Spring Cypress',
                  storePhone: '(281)304-1956',
                  state: 'TX',
                  distance: null,
                  inventory: {
                    quantity: 0,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                }
              ],
              __typename: 'Service'
            }
          ],
          type: 'pickup',
          __typename: 'FulfillmentOption'
        },
        {
          fulfillable: true,
          messages: [
            'express delivery',
            'ShipToHome null'
          ],
          services: [
            {
              type: 'express delivery',
              isDefault: true,
              deliveryCharge: '8.99',
              deliveryDates: {
                endDate: '2024-03-06',
                startDate: '2024-03-06',
                __typename: 'DeliveryDate'
              },
              deliveryTimeline: 'tomorrow',
              freeDeliveryThreshold: null,
              totalCharge: 8.99,
              locations: [
                {
                  type: 'store',
                  isAnchor: false,
                  locationId: '6838',
                  storeName: null,
                  storePhone: null,
                  state: null,
                  distance: null,
                  inventory: {
                    quantity: 0,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                }
              ],
              __typename: 'Service'
            },
            {
              type: 'sth',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: {
                endDate: '2024-03-06',
                startDate: '2024-03-06',
                __typename: 'DeliveryDate'
              },
              deliveryTimeline: 'tomorrow',
              freeDeliveryThreshold: null,
              totalCharge: 0,
              locations: [
                {
                  type: 'online',
                  isAnchor: false,
                  locationId: '8119',
                  storeName: null,
                  storePhone: null,
                  state: null,
                  distance: null,
                  inventory: {
                    quantity: 0,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                }
              ],
              __typename: 'Service'
            }
          ],
          type: 'delivery',
          __typename: 'FulfillmentOption'
        }
      ],
      __typename: 'Fulfillment'
    },
    info: {
      recommendationFlags: {
        visualNavigation: false,
        pipCollections: true,
        packages: false,
        ACC: true,
        collections: false,
        frequentlyBoughtTogether: true,
        bundles: false,
        batItems: true,
        __typename: 'RecommendationFlags'
      },
      quantityLimit: 0,
      __typename: 'Info'
    }
  },
  {
    itemId: '319376783',
    fulfillment: {
      anchorStoreStatusType: 'CLEARANCE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'GU,PR,VI',
      fulfillmentOptions: [
        {
          fulfillable: true,
          messages: [
            '6586 -InStock',
            '6838 InStock'
          ],
          services: [
            {
              type: 'bopis',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: null,
              deliveryTimeline: null,
              freeDeliveryThreshold: null,
              totalCharge: null,
              locations: [
                {
                  type: 'store',
                  isAnchor: true,
                  locationId: '6586',
                  storeName: 'Spring Cypress',
                  storePhone: '(281)304-1956',
                  state: 'TX',
                  distance: null,
                  inventory: {
                    quantity: 8,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                },
                {
                  type: 'store',
                  isAnchor: false,
                  locationId: '6838',
                  storeName: 'N. Eldridge',
                  storePhone: '(281)807-5128',
                  state: 'TX',
                  distance: 6.3351912,
                  inventory: {
                    quantity: 3,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                }
              ],
              __typename: 'Service'
            }
          ],
          type: 'pickup',
          __typename: 'FulfillmentOption'
        },
        {
          fulfillable: true,
          messages: [
            'express delivery',
            'ShipToHome null'
          ],
          services: [
            {
              type: 'express delivery',
              isDefault: true,
              deliveryCharge: '8.99',
              deliveryDates: {
                endDate: '2024-03-06',
                startDate: '2024-03-06',
                __typename: 'DeliveryDate'
              },
              deliveryTimeline: 'tomorrow',
              freeDeliveryThreshold: null,
              totalCharge: 8.99,
              locations: [
                {
                  type: 'store',
                  isAnchor: false,
                  locationId: '6838',
                  storeName: null,
                  storePhone: null,
                  state: null,
                  distance: null,
                  inventory: {
                    quantity: 8,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                }
              ],
              __typename: 'Service'
            },
            {
              type: 'sth',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: {
                endDate: '2024-03-07',
                startDate: '2024-03-07',
                __typename: 'DeliveryDate'
              },
              deliveryTimeline: 'later',
              freeDeliveryThreshold: null,
              totalCharge: 0,
              locations: [
                {
                  type: 'online',
                  isAnchor: false,
                  locationId: '8119',
                  storeName: null,
                  storePhone: null,
                  state: null,
                  distance: null,
                  inventory: {
                    quantity: 139,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                }
              ],
              __typename: 'Service'
            }
          ],
          type: 'delivery',
          __typename: 'FulfillmentOption'
        }
      ],
      __typename: 'Fulfillment'
    },
    info: {
      recommendationFlags: {
        visualNavigation: false,
        pipCollections: true,
        packages: false,
        ACC: true,
        collections: false,
        frequentlyBoughtTogether: true,
        bundles: false,
        batItems: true,
        __typename: 'RecommendationFlags'
      },
      quantityLimit: 0,
      __typename: 'Info'
    }
  },
  {
    itemId: '316630227',
    fulfillment: {
      anchorStoreStatusType: 'ACTIVE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'AK,GU,HI,PR,VI',
      fulfillmentOptions: [
        {
          fulfillable: true,
          messages: [
            '6586 -InStock'
          ],
          services: [
            {
              type: 'bopis',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: null,
              deliveryTimeline: null,
              freeDeliveryThreshold: null,
              totalCharge: null,
              locations: [
                {
                  type: 'store',
                  isAnchor: true,
                  locationId: '6586',
                  storeName: 'Spring Cypress',
                  storePhone: '(281)304-1956',
                  state: 'TX',
                  distance: null,
                  inventory: {
                    quantity: 4,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                }
              ],
              __typename: 'Service'
            }
          ],
          type: 'pickup',
          __typename: 'FulfillmentOption'
        },
        {
          fulfillable: true,
          messages: [
            'express delivery',
            'ShipToHome null'
          ],
          services: [
            {
              type: 'express delivery',
              isDefault: true,
              deliveryCharge: '8.99',
              deliveryDates: {
                endDate: '2024-03-06',
                startDate: '2024-03-06',
                __typename: 'DeliveryDate'
              },
              deliveryTimeline: 'tomorrow',
              freeDeliveryThreshold: null,
              totalCharge: 8.99,
              locations: [
                {
                  type: 'store',
                  isAnchor: false,
                  locationId: '6838',
                  storeName: null,
                  storePhone: null,
                  state: null,
                  distance: null,
                  inventory: {
                    quantity: 5,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                }
              ],
              __typename: 'Service'
            },
            {
              type: 'sth',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: {
                endDate: '2024-03-06',
                startDate: '2024-03-06',
                __typename: 'DeliveryDate'
              },
              deliveryTimeline: 'tomorrow',
              freeDeliveryThreshold: null,
              totalCharge: 0,
              locations: [
                {
                  type: 'online',
                  isAnchor: false,
                  locationId: '8119',
                  storeName: null,
                  storePhone: null,
                  state: null,
                  distance: null,
                  inventory: {
                    quantity: 140,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                }
              ],
              __typename: 'Service'
            }
          ],
          type: 'delivery',
          __typename: 'FulfillmentOption'
        }
      ],
      __typename: 'Fulfillment'
    },
    info: {
      recommendationFlags: {
        visualNavigation: false,
        pipCollections: true,
        packages: false,
        ACC: true,
        collections: false,
        frequentlyBoughtTogether: true,
        bundles: false,
        batItems: true,
        __typename: 'RecommendationFlags'
      },
      quantityLimit: 0,
      __typename: 'Info'
    }
  }
];

export const mockedNoShipButHasBopisProducts = [
  {
    itemId: '319376784',
    fulfillment: {
      anchorStoreStatusType: 'ACTIVE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'AK,GU,PR,VI',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'AK,GU,PR,VI',
      fulfillmentOptions: [
        {
          fulfillable: true,
          messages: [
            '6586 -InStock'
          ],
          services: [
            {
              type: 'bopis',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: null,
              deliveryTimeline: null,
              freeDeliveryThreshold: null,
              totalCharge: null,
              locations: [
                {
                  type: 'store',
                  isAnchor: true,
                  locationId: '6586',
                  storeName: 'Spring Cypress',
                  storePhone: '(281)304-1956',
                  state: 'TX',
                  distance: null,
                  inventory: {
                    quantity: 10,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                }
              ],
              __typename: 'Service'
            }
          ],
          type: 'pickup',
          __typename: 'FulfillmentOption'
        },
      ],
      __typename: 'Fulfillment'
    },
    info: {
      quantityLimit: 0,
    }
  },
];

export const mockedNoShipButHasNoBopisProducts = [
  {
    itemId: '319376784',
    fulfillment: {
      anchorStoreStatusType: 'ACTIVE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'GA,AK,GU,PR,VI,TX',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'GA,AK,GU,PR,VI,TX',
      fulfillmentOptions: [
        {
          fulfillable: true,
          messages: [
            '6586 -InStock'
          ],
          services: [
            {
              type: 'bopis',
              isDefault: true,
              deliveryCharge: null,
              deliveryDates: null,
              deliveryTimeline: null,
              freeDeliveryThreshold: null,
              totalCharge: null,
              locations: [
                {
                  type: 'store',
                  isAnchor: true,
                  locationId: '6586',
                  storeName: 'Spring Cypress',
                  storePhone: '(281)304-1956',
                  state: 'TX',
                  distance: null,
                  inventory: {
                    quantity: 0,
                    isInStock: true,
                    isOutOfStock: false,
                    isLimitedQuantity: false,
                    isUnavailable: false,
                    maxAllowedBopisQty: null,
                    minAllowedBopisQty: null,
                    __typename: 'Inventory'
                  },
                  __typename: 'Location'
                }
              ],
              __typename: 'Service'
            }
          ],
          type: 'pickup',
          __typename: 'FulfillmentOption'
        },
      ],
      __typename: 'Fulfillment'
    },
    info: {
      quantityLimit: 0,
    }
  },
];

export const mockedUnavailableProducts = [
  {
    itemId: '319376784',
    fulfillment: {
      anchorStoreStatusType: 'ACTIVE',
      backordered: false,
      backorderedShipDate: null,
      bossExcludedShipStates: 'GA,AK,GU,PR,VI,TX',
      bodfsAssemblyEligible: false,
      seasonStatusEligible: null,
      excludedShipStates: 'GA,AK,GU,PR,VI,TX',
      fulfillmentOptions: null,
      __typename: 'Fulfillment'
    },
    info: {
      quantityLimit: 0,
    }
  },
];
