import { useState, useEffect, useRef } from 'react';
import { debounce } from 'throttle-debounce';

const HeaderNodes = Object.freeze({
  ROOT: 'header-root',
  NAVIGATION: 'header-nav-container'
});

export const useHeaderObserver = () => {
  const [entry, setEntry] = useState({});

  const rootNodeRef = useRef(null);
  const navigationNodeRef = useRef(null);

  const intersectionHandler = ([observedEntry]) => setEntry({
    visible: observedEntry.isIntersecting,
    root: {
      clientRect: observedEntry.boundingClientRect
    },
    navigation: {
      clientRect: navigationNodeRef.current.getBoundingClientRect(),
      positionBottom: ('positionBottom' in navigationNodeRef.current.dataset)
    }
  });

  const resizeHandler = debounce(166, ([observedEntry]) => setEntry((prevState) => ({
    ...prevState,
    root: {
      clientRect: observedEntry.contentRect
    },
    navigation: {
      clientRect: navigationNodeRef.current.getBoundingClientRect(),
      positionBottom: ('positionBottom' in navigationNodeRef.current.dataset)
    }
  })));

  const intersectionObserverRef = useRef(
    typeof (window) !== 'undefined' && new window.IntersectionObserver(intersectionHandler)
  );

  const resizeObserverRef = useRef(
    typeof (window) !== 'undefined' && !!window.ResizeObserver && new window.ResizeObserver(resizeHandler)
  );

  useEffect(() => {
    rootNodeRef.current = document.querySelector(`#${HeaderNodes.ROOT}`);
    navigationNodeRef.current = document.querySelector(`#${HeaderNodes.NAVIGATION}`);

    if (rootNodeRef.current) {
      intersectionObserverRef.current.observe(rootNodeRef.current);
      resizeObserverRef.current.observe(rootNodeRef.current);
    }

    return () => {
      resizeObserverRef.current.disconnect();
      intersectionObserverRef.current.disconnect();
    };
  }, []);

  return [entry];
};