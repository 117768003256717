import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

/**
 * Props
 *
 * @typedef {object} Props
 * @property {number} productsCount - The number of products in the bundle
 */

/**
 * Returns a formated Title.
 * @param {Props} Props
 * @returns {React.ReactElement} JSX
 */

const ProductListTitle = ({ productsCount }) => {
  const listProductTitle = `Items in this bundle (${productsCount})`;

  return (
    <Typography variant="body-lg" weight="bold" data-testid="product-list-header">
      {listProductTitle}
    </Typography>
  );
};

ProductListTitle.propTypes = {
  productsCount: PropTypes.number.isRequired
};

ProductListTitle.displayName = 'ProductListTitle';

export { ProductListTitle };
