import React from 'react';
import PropTypes from 'prop-types';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import {
  params,
  shape as shapeType,
  string as stringType,
  useDataModel,
  extend,
} from '@thd-nucleus/data-sources';
import { Skeleton, SkeletonLine, SkeletonContent } from '@one-thd/sui-atomic-components';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { EtchWidgets } from '@thd-olt-component-react/etch-widgets';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { DISPLAY_MODE_SALSIFY, DISPLAY_MODE_REVJET, DISPLAY_MODE_ETCH } from '../../constants';
import { RevJet } from './providers/RevJet';
import { Salsify } from './providers/Salsify';

const placeholder = (
  <Skeleton PaperProps={{
    className: 'sui-grow sui-border-0'
  }}
  >
    <SkeletonContent grow disableShrink initialSize="full" orientation="vertical">
      <SkeletonLine
        fullWidth
        numberOfLines={6}
      />
    </SkeletonContent>
  </Skeleton>
);

export const RichContentComponent = ({ itemId }) => {
  const { data, loading, error } = useDataModel('product', { variables: { itemId } });

  if (loading) {
    return (
      placeholder
    );
  }

  if (!data || error || !itemId) {
    return null;
  }

  const {
    richContent
  } = data?.product?.media || {};

  const etchContent = richContent?.content;
  const displayMode = typeof richContent?.displayMode === 'string'
    ? richContent?.displayMode.toLowerCase() : richContent?.displayMode;

  const salsifyRichContent = richContent?.salsifyRichContent;

  const salsify = displayMode?.toLowerCase() === DISPLAY_MODE_SALSIFY;
  const revJet = displayMode === DISPLAY_MODE_REVJET;
  const etchWidgets = displayMode?.indexOf(DISPLAY_MODE_ETCH) > -1;

  if (!richContent || (!revJet && !salsify && !etchWidgets)) {
    return null;
  }

  return (
    <div className="sui-mb-8 sui-flex sui-flex-row sui-justify-center" data-testid="rich-content">
      {revJet && (
        <RevJet itemId={itemId} parentId="revJet-key-features-summary" />
      )}
      {!revJet && salsify && (
        <Salsify salsifyRichContent={salsifyRichContent} parentId="salsify-key-features-summary" />
      )}
      {!revJet && !salsify && etchWidgets && (
        <EtchWidgets content={etchContent} />
      )}
    </div>
  );
};

const dataModel = extend({
  product: params({ itemId: stringType().isRequired() }).shape({
    dataSources: stringType(),
    itemId: stringType(),
    media: shapeType({
      richContent: shapeType({
        content: stringType(),
        displayMode: stringType(),
        salsifyRichContent: stringType()
      })
    })
  })
}, EtchWidgets);

const propTypes = {
  itemId: PropTypes.string.isRequired
};

RichContentComponent.displayName = 'RichContent';
RichContentComponent.dataModel = dataModel;
RichContentComponent.propTypes = propTypes;

const HydratedComponent = withHydrator({
  delay: 1500,
  id: 'product-section-rich-content',
  placeholder,
  scrollBuffer: 750,
}, RichContentComponent);

const DynamicComponent = withDynamicComponent(HydratedComponent);
const RichContent = withErrorBoundary(DynamicComponent);

// RichContent.dataModel = dataModel;
// RichContent.propTypes = propTypes;
RichContent.displayName = 'RichContent';
export { RichContent };
