/* eslint-disable max-len */
import fetch from 'node-fetch';
import { isLLc } from './utils';
import { PAYPAL_INIT, SIGN_IN } from '../constants/constants';
import { logAnalytics, getAnalyticsEventName } from './AnalyticsHelper';
import { paypalSmartButtonInitFailNewRelic, paypalMissingData } from './NewRelicHelper';

export const generateInitUrl = (orderId, fromPage, itemCount, ffm) => {
  const urlDomain = !isLLc() ? 'https://www.homedepot.com' : 'https://hd-qa74.homedepotdev.com';
  // mcc-checkout will set paypal express as true if fromPage is shoppingCart or addToCart
  // for any other values it will be set as false.
  const urlParams = '?fromPage=' + fromPage
    + '&orderId=' + orderId
    + '&itemType=' + ffm
    + '&itemCount=' + itemCount
    // These dont seem to do anything in mcc-checkout
    + '&myCart=true&opc=true';
  return urlDomain + PAYPAL_INIT + urlParams;
};

export const fetchPaypalToken = (orderId, fromPage, itemCount, ffm) => {
  let fromPageParam = fromPage === 'pip' ? 'shoppingCart' : fromPage;
  const options = {
    method: 'GET',
    cache: 'no-cache',
    credentials: 'include',
  };

  const req = new fetch.Request(generateInitUrl(orderId, fromPageParam, itemCount, ffm), options);
  return req;
};

export const onApprove = async (
  paypalData,
  orderId,
  isNucleusPaypalCheckout,
  isRegularPaypal,
  returnUrl,
  triggerSubmit
) => {
  try {
    if (isRegularPaypal) {
      const parsedReturnUrl = new URL(returnUrl);
      if (parsedReturnUrl.searchParams.get('navigation') === 'regularPaypal') {
        triggerSubmit(null, paypalData);
        return true;
      }
      return false;
    }
    if (isNucleusPaypalCheckout) {
      const payPalReviewUrl = '/checkout?cartId=' + orderId + '&orderId=' + orderId
        + '&token=' + paypalData?.orderID + '&payerID=' + paypalData?.payerID
        + '&navigation=expressPaypal';
      window.top.location.href = payPalReviewUrl;
    } else {
      const payPalReviewUrl = '/mycheckout/checkout?orderId=' + orderId
      + '&navigation=expressPaypal&token=' + paypalData?.orderID
      + '&PayerID=' + paypalData?.payerID;
      window.top.location.href = payPalReviewUrl;
    }

  } catch (error) {
    console.log('Paypal on approve error: ' + error);
  }
  return true;
};

export function addAlert(showAlert) {
  try {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line no-unused-expressions
      window?.LIFE_CYCLE_EVENT_BUS?.trigger('checkout.addPaypalAlert', { showAlert });
    }
  } catch (err) {
    console.error(err);
  }
}

export const createOrder = (cartId, hasSavedPaypal, payInFour, fromPage, itemCount, ffm, cartRequest, itemPrice) => {
  if (!cartId || !fromPage || !itemCount || !ffm) {
    const missingProps = ((cartId && cartId !== '') ? '' : 'cartId ')
      + ((itemCount && itemCount !== '') ? '' : 'itemCount ')
      + ((ffm && ffm !== '') ? '' : 'ffm ')
      + ((fromPage && fromPage !== '') ? '' : 'fromPage ');
    paypalMissingData(fromPage, payInFour, missingProps);
    return false;
  }

  const eventName = getAnalyticsEventName(hasSavedPaypal, payInFour, fromPage);
  logAnalytics({ eventName, eventData: { cartRequest, itemPrice } });
  return fetch(fetchPaypalToken(cartId, fromPage, itemCount, ffm))
    .then((response) => response.json())
    .then((response) => {
      if (response?.checkoutModel?.errorModel?.errorData?.[0].error?.[0]) {
        const error = response?.checkoutModel?.errorModel?.errorData?.[0].error?.[0];
        paypalSmartButtonInitFailNewRelic(
          error.errorCode, error.description, payInFour);
        LIFE_CYCLE_EVENT_BUS.trigger('paypal-checkout-button.initError');
        return false;
      }

      if (response?.checkoutModel?.authRedirectErrorModel?.errorDescription === 'Session Expired') {
        paypalSmartButtonInitFailNewRelic(
          response?.checkoutModel?.authRedirectErrorModel?.errorDescription, '', payInFour);
        window.top.location = window?.location?.origin + '/' + SIGN_IN + '?pp_persisted=true';
      }
      if (response?.checkoutModel?.paypalModel) {
        return response.checkoutModel.paypalModel.token;
      }
      if (response?.status) {
        paypalSmartButtonInitFailNewRelic(
          response?.status, '', payInFour);
        LIFE_CYCLE_EVENT_BUS.trigger('paypal-checkout-button.initError');
        return false;
      }
      return false;
    }).catch((errorData) => {
      paypalSmartButtonInitFailNewRelic(errorData, '', payInFour);
      LIFE_CYCLE_EVENT_BUS.trigger('paypal-checkout-button.initError');
      console.log('Paypal create order error: ' + errorData);
    });
};
