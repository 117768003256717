import React from 'react';
import PropTypes from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { Sharebelt, ShareFavorite } from '@thd-olt-component-react/thd-sharebelt';
import { getB2BUser } from '../utils/product-bundle-utils';

const BundleShare = ({
  disable,
  itemId
}) => {

  if (disable || getB2BUser() || itemId === null) {
    return null;
  }

  return (
    <Sharebelt itemId={itemId}>
      <ShareFavorite showCircle showFavoritesCount />
    </Sharebelt>
  );

};

BundleShare.displayName = 'BundleShare';

BundleShare.propTypes = {
  disable: PropTypes.bool,
  itemId: PropTypes.string.isRequired
};
BundleShare.defaultProps = {
  disable: false,
};

BundleShare.dataModel = extend({}, Sharebelt);

export { BundleShare };