import React, { useState, useEffect } from 'react';

export const useBogoInfo = () => {
  /**
   * bogoInfo: {
   *   bogoSelectedProduct: Product data
   *   isFreeProduct: Boolean
   * }
   */
  const [bogoInfo, setBogoInfo] = useState();
  const bogoSelectedProductOnChange = ({ output: bogoData } = {}) => {
    let newBogoInfo = null;

    if (bogoData?.bogoSelectedProduct?.itemId
      && typeof bogoData.isFreeProduct === 'boolean'
    ) newBogoInfo = bogoData;
    else if (bogoData?.itemId) {
      newBogoInfo = {
        bogoSelectedProduct: bogoData,
        isFreeProduct: true,
      };
    }

    setBogoInfo(newBogoInfo);
  };
  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('buybox.bogoSelectedProduct', bogoSelectedProductOnChange);
    return () => window.LIFE_CYCLE_EVENT_BUS.lifeCycle.off('buybox.bogoSelectedProduct');
  }, []);
  return bogoInfo;
};
