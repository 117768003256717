import { useState, useEffect } from 'react';
import {
  getCartItems,
} from '../utils/product-bundle-utils';

const useGetCartItems = ({ productsFulfillmentInformation, bundleQuantity, storeId, storeZip }) => {
  const [cartItems, setcartItems] = useState(null);

  useEffect(() => {
    if (productsFulfillmentInformation && bundleQuantity) {
      const cartItemsData = getCartItems({
        cartingOptions: productsFulfillmentInformation?.cartingOptions,
        storeId,
        storeZip,
        qty: bundleQuantity,
      });

      setcartItems(cartItemsData);
    }
  }, [productsFulfillmentInformation, bundleQuantity]);

  return { cartItems };
};

export default useGetCartItems;
