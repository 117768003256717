import React from 'react';
import {
  arrayOf, bool, func, shape, string, number, instanceOf, elementType
} from 'prop-types';
import { Image, Col, Row } from '@thd-olt-component-react/core-ui';
import { Button } from '@thd-olt-component-react/button';
import { useConfigService } from '@thd-nucleus/experience-context';
import { Review } from '../Review/Review.component';
import { Overview } from '../Overview/Overview';
import { CustomerImageCarousel } from '../CustomerImageCarousel/CustomerImageCarousel.component';
import { ReviewPager } from '../Paging/ReviewPager.component';
import { Search, Sort, AdditionalFilter } from '../Filter/components';
import { Filter } from '../Filter/Filter.component';

import { PagerDisplayText } from '../PagerDisplayText/PagerDisplayText';
import { ReviewSentiments } from '../Filter/components/ReviewSentiments/ReviewSentiments.component';
import { PropTypes as SentimentPropTypes } from '../Filter/components/ReviewSentiments/Sentiment';
import './MobileOverlay.scss';
import { MagicApron } from '../MagicApron/MagicApron';

export const MobileOverlay = ({
  apiKey,
  brandName,
  imageUrl,
  itemId,
  loading,
  reviewModels,
  reviewStatistics,
  filterCheckboxes,
  noResults,
  onDeselectFilter,
  onFilterChange,
  onAdditionalFilterChange,
  onPageChange,
  onSearch,
  onSortChange,
  onSentimentChange,
  onSearchClear,
  pageContext,
  productLabel,
  prosAndCons,
  searchTerm,
  sortBy,
  selected,
  selectedSentiment,
  statistics,
  category,
  storeId,
  seoPageNumber,
  carouselRef,
  totalReviewPages,
  hideOverlay,
  searchAndSortRef,
  selectedReviewSummaryAspect,
  onAspectClick
}) => {
  const magicApronEnabled = useConfigService('fs-prop:magicapron-reviewFormat');
  return (
    <>
      {!hideOverlay
        && (
          <Row className="rr-mobile-overlay">
            {imageUrl && brandName && productLabel && (
              <>
                <Col fallback={4}>
                  <div className="rr-mobile-overlay__product-image">
                    <Image
                      src={(imageUrl).replace(/65(?=(.(jpg|webp)))/, '145')}
                      alt={productLabel}
                      lazy
                      width={100}
                      height={100}
                    />
                  </div>
                </Col>
                <Col fallback={8}>
                  <div className="rr-mobile-overlay__product-brand">
                    {brandName}
                  </div>
                  <div className="rr-mobile-overlay__product-label">
                    {productLabel}
                  </div>
                </Col>
              </>
            )}
          </Row>
        )}
      <Overview
        isMobileOverlay
        itemId={itemId}
        reviewStatistics={reviewStatistics}
        filters={[]}
        filterCheckboxes={filterCheckboxes}
        clickableFilters
        onFilterChange={onFilterChange}
        category={category}
        storeId={storeId}
        apiKey={apiKey}
      />
      {
        category === 'product' && magicApronEnabled && (
          <div className="magic-apron_wrapper">
            <MagicApron
              itemId={itemId}
              selectedAspect={selectedReviewSummaryAspect}
              onAspectClick={onAspectClick}
            />
          </div>
        )
      }
      {category === 'product' && (
        <div className="customer-image-carousel__wrapper" ref={carouselRef}>
          <CustomerImageCarousel itemId={itemId} />
        </div>
      )}
      {category === 'product' && prosAndCons
        ? (
          <ReviewSentiments
            itemId={itemId}
            selectedSentiment={selectedSentiment}
            onSentimentChange={onSentimentChange}
          />
        ) : ''}
      {category === 'store' && (
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            fallback={12}
            className="ratings-and-reviews__search-and-filter"
          >
            <AdditionalFilter
              ratingFilters={filterCheckboxes}
              onFilterChange={onAdditionalFilterChange}
              reviewStatistics={reviewStatistics}
            />
          </Col>
        </Row>
      )}
      <Row className="ratings-and-reviews__search-and-sort">
        <Col xs={12} sm={12} md={4} lg={4} className="ratings-and-reviews__search-and-filter">
          <Search
            onSearch={onSearch}
            searchTerm={searchTerm}
            noResults={reviewModels.length === 0}
            ref={searchAndSortRef}
          />
        </Col>
        {category === 'product' && (
          <Col xs={12} sm={12} md={5} lg={5} className="ratings-and-reviews__search-and-filter" id="search-and-sort">
            <Filter
              onChange={onFilterChange}
              ratingFilters={filterCheckboxes}
              reviewStatistics={reviewStatistics}
              selectedSentiment={selectedSentiment}
            />
          </Col>
        )}
      </Row>
      <Row className="ratings-reviews__sort-and-filter">
        <Col className="ratings-reviews__sort-and-filter__pager" fallback={7} sm={3} md={3} lg={3}>
          <PagerDisplayText
            topPager
            loading={loading}
            statistics={statistics}
            pageContext={pageContext}
            seoPageNumber={seoPageNumber}
          />
        </Col>
        <Col fallback={5} xs={5} sm={5}>
          <Sort onChange={onSortChange} sortBy={sortBy} />
        </Col>
        {(searchTerm || selected.length > 0)
          && (
            <Col className="ratings-reviews__sort-and-filter__mobile-filter-pill-collection" fallback={12}>
              {selected.length > 0
                && (
                  <>
                    {selected
                      .map((checkbox, checkboxIndex) => {
                        let pillText;
                        if (checkbox.value === 'verified') pillText = 'Verified Purchases';
                        else if (checkbox.id?.includes('rating')) pillText = `${checkbox.value} Star`;
                        else if (checkbox.id?.includes('age')) pillText = checkbox.label;
                        else pillText = checkbox.value;
                        return (
                          <Button
                            key={checkboxIndex}
                            id={checkbox.id}
                            onClick={() => { onDeselectFilter(checkbox); }}
                            filter
                          >
                            {selectedSentiment ? selectedSentiment.Feature : pillText}
                          </Button>
                        );
                      }
                      )}
                  </>
                )}
              {
                searchTerm
                && (
                  <Button
                    key="search"
                    id="search"
                    onClick={onSearchClear}
                    filter
                  >
                    {noResults && searchTerm ? 'Clear Search Term' : searchTerm}
                  </Button>
                )
              }
            </Col>
          )}
      </Row>
      {reviewModels.length === 0 ? <div className="loadingBuffer" />
        : reviewModels.map((review, reviewIndex) => {
          return (
            <Review
              review={review}
              id={reviewIndex}
              key={`review-${reviewIndex}`}
              apiKey={apiKey}
              searchTerm={searchTerm}
              category={category}
            />
          );
        })}
      <Row className="pager-section--mobile">
        <ReviewPager
          itemId={itemId}
          reviewsPageContext={pageContext}
          onPageChanged={onPageChange}
          category={category}
          storeId={storeId}
          totalReviewPages={totalReviewPages}
        />
        <PagerDisplayText
          loading={loading}
          statistics={statistics}
          pageContext={pageContext}
          seoPageNumber={seoPageNumber}
        />
      </Row>
    </>
  );
};

MobileOverlay.displayName = 'RatingsAndReviewsMobileOverlay';

MobileOverlay.propTypes = {
  apiKey: string,
  brandName: string.isRequired,
  category: string.isRequired,
  imageUrl: string,
  itemId: string.isRequired,
  loading: bool,
  noResults: bool,
  onDeselectFilter: func,
  onSearchClear: func.isRequired,
  onFilterChange: func,
  onAdditionalFilterChange: func,
  onPageChange: func,
  onSearch: func,
  onSortChange: func,
  onSentimentChange: func,
  pageContext: shape({
    currentPage: number
  }),
  productLabel: string.isRequired,
  prosAndCons: bool.isRequired,
  searchTerm: string,
  reviewModels: arrayOf(shape({})),
  reviewStatistics: shape({}),
  filterCheckboxes: arrayOf(shape({})),
  selected: arrayOf(shape({})),
  selectedSentiment: SentimentPropTypes.selectedSentiment,
  sortBy: string.isRequired,
  statistics: shape({
    totalReviewCount: number
  }),
  storeId: string,
  seoPageNumber: number,
  totalReviewPages: number,
  carouselRef: shape({ current: shape({}) }),
  hideOverlay: bool,
  searchAndSortRef: shape({ current: shape({ scrollIntoView: func }) }),
  selectedReviewSummaryAspect: string,
  onAspectClick: func
};
MobileOverlay.defaultProps = {
  apiKey: '',
  filterCheckboxes: [],
  imageUrl: '',
  loading: false,
  noResults: true,
  onDeselectFilter: () => { },
  onFilterChange: () => { },
  onAdditionalFilterChange: () => { },
  onPageChange: () => { },
  onSearch: () => { },
  onSortChange: () => { },
  onSentimentChange: () => { },
  pageContext: null,
  reviewModels: [],
  reviewStatistics: {},
  selected: [],
  selectedSentiment: {},
  searchTerm: '',
  statistics: null,
  storeId: null,
  seoPageNumber: null,
  totalReviewPages: null,
  carouselRef: null,
  hideOverlay: false,
  searchAndSortRef: null,
  selectedReviewSummaryAspect: null,
  onAspectClick: () => { }
};
