import React from 'react';
import {
  func, string, oneOf, shape, number, arrayOf
} from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { Carousel } from '@one-thd/sui-carousel';
import { dataModel } from '../ratings-and-reviews-data-model';

export const CustomerImageCarousel = ({
  minimumNumberOfImages, carouselPhotos, onCarouselClick,
}) => {
  if (carouselPhotos.length < minimumNumberOfImages) {
    return null;
  }

  return (
    <div data-component="CustomerImageCarousel">
      <Typography variant="h3" weight="bold">Customer Images</Typography>
      <Carousel
        breakpoints={{
          sm: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 16
          },
          md: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 16
          },
          lg: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 16
          },
          xl: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 16
          }
        }}
        type="content"
        spaceBetween={8}
        loop={false}
        disableMargin
      >
        {
          carouselPhotos.map((photo, photoIndex) => {
            const thumbNailImg = photo.Sizes ? `url(${photo.Sizes.normal.Url})` : '';
            return (
              <button
                key={`${photoIndex}`}
                aria-label="img"
                type="button"
                style={{
                  backgroundImage: (thumbNailImg)
                }}
                data-testid="customerImageButton"
                className="sui-h-full sui-w-full sui-aspect-square sui-bg-cover"
                onClick={() => onCarouselClick(photo.Id)}
              />
            );
          })
        }
      </Carousel>
    </div>
  );
};

CustomerImageCarousel.displayName = 'CustomerImageCarousel';

CustomerImageCarousel.propTypes = {
  carouselPhotos: arrayOf(shape({})).isRequired,
  onCarouselClick: func.isRequired,
  minimumNumberOfImages: number,
};

CustomerImageCarousel.defaultProps = {
  minimumNumberOfImages: 3,
};

CustomerImageCarousel.dataModel = {
  reviewPhotos: dataModel?.reviewPhotos
};
