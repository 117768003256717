/* eslint-disable tailwindcss/no-custom-classname */
import React, {
  useEffect, useRef, useCallback, useState, useContext
} from 'react';
// eslint-disable-next-line no-restricted-imports
import { Image } from '@thd-olt-component-react/core-ui';
import { Col, Row } from '@thd-olt-component-react/grid';
import { AddToList } from '@thd-olt-component-react/add-to-list';
import { StickyWithHeaderObserver } from '@thd-olt-component-react/sticky';
import { RatingMeter } from '@one-thd/sui-atomic-components';
import { PaintSwatches } from '@one-thd/sui-icons';
import { ExperienceContext, useStoreId } from '@thd-nucleus/experience-context';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  alias,
  arrayOf as arrayType,
  number as numberType,
  params,
  shape as shapeType,
  string as stringType,
  extend,
  useDataModel
} from '@thd-nucleus/data-sources';

import {
  useReviewsDeprecated
} from '@thd-nucleus/data-sources/react/dataModel/migration';
import { Price } from '@thd-olt-component-react/price';
import { useLifeCycleEventBus } from '@thd-olt-functional/utils';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import helpers from './helpers';
import TopSvg from './components/TopSvg.component';
import './sticky-nav.style.scss';
import { Link, Links, SmoothScroll } from './components';

const StickyNav = React.forwardRef((props, stickyNavRef) => {

  const {
    channel: channelProp,
    children,
    offset,
    itemId,
    hideIfHDPPSku,
    hideSaveToFavorite,
    ratingsRef,
    onRatingsClick,
    configId
  } = props;

  const ctx = useContext(ExperienceContext);
  const [hideStickyNav, setHideStickyNav] = useState(true);

  const { isExchangeCustomer } = useThdCustomer() || {};

  const channel = channelProp || ctx?.channel || 'desktop';

  const goToTop = useCallback(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return null;
    }
    const displayStickyNav = () => {
      const shouldHide = window.pageYOffset < offset;
      if (hideStickyNav !== shouldHide) {
        setHideStickyNav(shouldHide);
      }
    };
    window.addEventListener('scroll', displayStickyNav, { passive: true });
    return () => {
      window.removeEventListener('scroll', displayStickyNav);
    };
  }, [hideStickyNav]);

  useEffect(() => {
    const { onGetElement } = props;
    if (!helpers.ref && stickyNavRef.current) {
      helpers.setStickyNavRef(stickyNavRef);
      if (onGetElement) {
        onGetElement(stickyNavRef);
      }
    }

  }, [stickyNavRef?.current]);

  // const reviewService = useDataModel('reviews', { variables: { itemId } });
  const reviewService = useReviewsDeprecated({ itemId });
  const reviewData = reviewService.data?.reviews || {};
  const totalResults = reviewData?.TotalResults;
  const reviewProducts = reviewData?.Includes?.Products;
  const matchingReviewCount = reviewProducts?.items?.find((i) => {
    return i?.FilteredReviewStatistics?.TotalReviewCount === totalResults;
  });
  const averageOverallRating = matchingReviewCount?.FilteredReviewStatistics?.AverageOverallRating
    || reviewProducts?.store?.FilteredReviewStatistics?.AverageOverallRating;
  const storeId = useStoreId();
  const { data, error, loading } = useDataModel('clientOnlyProduct',
    {
      ssr: false,
      variables: {
        itemId,
        storeId,
        configId,
        skipPaintDetails: !configId
      },
      skip: storeId === '8119'
    });

  const {
    configuredAssetUrl,
    configuredProductLabel
  } = useLifeCycleEventBus('configurator.configurator_sticky_nav_event');
  const { configuratorPricing } = useLifeCycleEventBus('configurator.configurator_pricing_event');

  if ((error && !data) || !data || loading) {
    return null;
  }

  if (!data?.product) {
    return null;
  }

  const { product = {} } = data || {};
  const {
    identifiers: {
      brandName,
      productLabel
    },
    paintDetails: {
      colorType,
      colorDisplayName,
      brandLogo,
      rgb = {}
    } = {},
    pricing = {},
    reviews = {}
  } = data?.product || {};

  const { ratingsReviews = {} } = reviews || {};
  const { averageRating, totalReviews } = ratingsReviews || {};
  const fallbackPricing = configuratorPricing?.itemId === itemId ? configuratorPricing?.pricing : pricing;
  const { value } = fallbackPricing || {};
  const image = product?.media?.image;
  const reviewCount = typeof totalResults === 'number' ? totalResults : totalReviews || 0;
  const avgRating = reviewCount === 0 ? 0
    : averageOverallRating || averageRating || 0;
  const hasAverageRating = (avgRating === null) ? 0 : parseFloat(avgRating);
  let productImage = (
    <Image
      src={configuredAssetUrl || image?.url.replace(/_1000./, '_100.')}
      alt="Product Image"
      height="1"
      width="1"
    />
  );

  if (colorType && colorType !== 'CustomColor' && rgb) {
    productImage = (
      <div
        className="sticky-nav__thumbnailImageFitBrand"
        style={{
          backgroundColor: `rgb(${rgb.red},${rgb.green},${rgb.blue})`,
        }}
      >
        <Image
          src={brandLogo}
          alt="Brand Image"
          lazy
        />
      </div>
    );
  } else if (colorType === 'CustomColor' || !rgb) {
    productImage = (
      <div
        className={classNames('sui-bg-inactive',
          'sui-flex',
          'sui-items-center',
          'sui-justify-center',
          'sui-w-full',
          'sui-h-full'
        )}
      >
        <div
          className="sui-text-center sui-flex sui-items-center sui-justify-center"
        >
          <PaintSwatches color="medium" size="large" />
        </div>
      </div>
    );
  }
  const passRef = (child) => {
    return child?.type?.name === (<Links />).type.name ? React.cloneElement(child, { stickyNavRef }) : child;
  };

  return (
    <StickyWithHeaderObserver
      offsetScroll={offset}
      position="top"
      stickyByDefault
      className="sui-w-full sui-left-0"
    >
      <div
        id="sticky-nav"
        className="sticky-nav print:sui-hidden"
        ref={stickyNavRef}
        data-section="sticky-nav"
        data-component="StickyNav"
      >
        <div className="sticky-nav__inner-wrapper">
          <Row>
            <Col className="sticky-nav--no-top-bottom-padding">
              <div className="sticky-nav__row">
                <div className="sticky-nav__thumbnail sui-ml-4 sui-mt-4 sui-w-16 sui-mb-4">
                  {productImage}
                </div>
                <div
                  className="sticky-nav__product_details_wrapper sui-ml-4 sui-flex-1 sui-overflow-hidden sui-mr-2"
                >
                  <div className="sticky-nav__product_details sticky-nav__row">
                    <Col fallback="11">
                      <div className="title">{configuredProductLabel || productLabel}</div>
                    </Col>
                  </div>
                  {colorDisplayName
                  && (
                    <div className="sticky-nav__product_details_magic_color sticky-nav__row sui-mt-1">
                      <Col fallback="11">
                        <div className="title">{`Color: ${colorDisplayName}`}</div>
                      </Col>
                    </div>
                  )}
                  <div className="grid flush sticky-nav__brand_and_price sticky-nav__row">
                    <Col fallback="7" xs="7" sm="8" className="sticky-nav__brand_and_review">
                      {brandName
                        && (
                          <div className="sticky-nav__brand--container">
                            <span>by </span>
                            <span className="brand">{brandName}</span>
                          </div>
                        )}
                      <div className="sui-flex sui-flex-nowrap sui-items-center">
                        {!(hideIfHDPPSku && data?.product?.identifiers?.skuClassification === 'hdpp')
                          && (
                            <div className="sticky-nav__ratings-reviews">
                              <div
                                className="ratings dynamic_ratings"
                                onClick={onRatingsClick}
                                role="button"
                                tabIndex={0}
                              >
                                <SmoothScroll targetRef={ratingsRef}>
                                  <RatingMeter
                                    className="sui-p-0"
                                    value={hasAverageRating}
                                    label={`(${reviewCount})`}
                                    RatingProps={{
                                      color: 'brand'
                                    }}
                                  />
                                </SmoothScroll>
                              </div>
                            </div>
                          )}
                        {!hideSaveToFavorite && !isExchangeCustomer && (
                          <div data-testid="sticky-nav__favorite" className="sticky-nav__favorite-count">
                            <AddToList itemId={itemId} showIconButton showCount />
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col
                      fallback="5"
                      xs="5"
                      sm="4"
                      data-testid={`sticky-nav__price-value--${value}`}
                      className={classNames('sticky-nav__price', { 'sticky-nav__price--hide-price': !value })}
                    >
                      <Price
                        itemId={itemId}
                        channel="desktop"
                        omitDetails
                        large={false}
                        displayEachUom={false}
                      />
                    </Col>
                  </div>
                </div>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <button
                  type="button"
                  className="sticky-nav__to-top-button"
                  onClick={goToTop}
                  name="sticky-nav_button--gototop"
                >
                  <TopSvg />
                </button>
              </div>
              <div className="sticky-nav__jump-links">
                {React.Children.map(children, passRef)}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </StickyWithHeaderObserver>
  );
});

StickyNav.displayName = 'StickyNav';

StickyNav.dataModel = extend({
  clientOnlyProduct: alias('product').params({ itemId: stringType().isRequired() }).shape({
    dataSources: stringType(),
    itemId: stringType(),
    identifiers: shapeType({
      brandName: stringType(),
      productLabel: stringType(),
      skuClassification: stringType()
    }),
    paintDetails: params({ storeId: stringType(), configId: stringType() }).shape({
      colorDisplayName: stringType(),
      colorType: stringType(),
      brandLogo: stringType(),
      rgb: shapeType({
        red: stringType(),
        green: stringType(),
        blue: stringType(),
      })
    }).skip('skipPaintDetails', true),
    media: shapeType({
      image: shapeType({
        url: stringType()
      }).client(),
      images: arrayType(shapeType({
        url: stringType(),
        sizes: arrayType(stringType()),
        type: stringType(),
        subType: stringType()
      }))
    }),
    pricing: params({ storeId: stringType() }).shape({
      value: numberType(),
      unitOfMeasure: stringType()
    }),
    reviews: shapeType({
      ratingsReviews: shapeType({
        averageRating: numberType(),
        totalReviews: numberType()
      })
    })
  }),
  reviews: params({ itemId: stringType().isRequired() }).shape({
    Includes: shapeType({
      Products: shapeType({
        items: arrayType(shapeType({
          FilteredReviewStatistics: shapeType({
            AverageOverallRating: stringType(),
            TotalReviewCount: numberType()
          })
        })),
        store: shapeType({
          FilteredReviewStatistics: shapeType({
            AverageOverallRating: stringType()
          })
        })
      })
    }),
    TotalResults: numberType()
  })
}, AddToList, Price);

StickyNav.propTypes = {
  channel: PropTypes.oneOf(['desktop', 'mobile']),
  children: PropTypes.node,
  itemId: PropTypes.string.isRequired,
  configId: PropTypes.string,
  offset: PropTypes.number,
  /** onComplete Function. */
  onGetElement: PropTypes.func,
  ratingsRef: PropTypes.shape({
    /* eslint-disable-next-line */
    current: PropTypes.any,
  }),
  onRatingsClick: PropTypes.func,
  hideIfHDPPSku: PropTypes.bool,
  hideSaveToFavorite: PropTypes.bool
};
StickyNav.defaultProps = {
  channel: null,
  children: null,
  offset: 850,
  onGetElement: null,
  ratingsRef: null,
  onRatingsClick: null,
  hideIfHDPPSku: false,
  hideSaveToFavorite: false,
  configId: undefined
};

StickyNav.Links = Links;
StickyNav.Link = Link;

export { StickyNav, Links, Link };
