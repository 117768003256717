import React from 'react';
import { string, bool, shape } from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { useLifeCycleEventBus } from '@thd-olt-functional/utils';
import { ConsumerCardMessaging } from '@thd-olt-component-react/consumer-card-messaging';
import { isMajorAppliances, getCustomerType } from '../utils/product-bundle-utils';

/**
 * Props
 *
 * @typedef {object} Props
 * @property {Object} product - Bundle product
 * @property {{placeholders: boolean,preservePlaceholders: boolean}} clsRemediation - Cls placeholders
 */

/**
 * Returns a component, Salient poinst bullets
 * @param {Props} Props
 * @returns {React.ReactElement} JSX
 */
const BundleConsumerCardMessaging = ({
  product,
  clsRemediation
}) => {
  const isAppliance = isMajorAppliances(product);
  const { configuratorPricing } = useLifeCycleEventBus('configurator.configurator_pricing_event');
  const price = configuratorPricing?.pricing?.value;
  if (!price || getCustomerType() === 'B2B') return null;

  return (
    <div data-component="BundleConsumerCardMessaging" data-testid="bundle-consumer-card-messaging">
      <ConsumerCardMessaging
        bundlePrice={String(price)}
        isAppliance={isAppliance}
        clsRemediation={clsRemediation}
      />
    </div>
  );
};

BundleConsumerCardMessaging.propTypes = {
  product: shape({
    bundleSpecificationDetails: shape({
      type: string
    }),
  }).isRequired,
  clsRemediation: shape({
    placeholders: bool,
    preservePlaceholders: bool
  })
};

BundleConsumerCardMessaging.defaultProps = {
  clsRemediation: {
    placeholders: false,
    preservePlaceholders: false
  }
};

BundleConsumerCardMessaging.displayName = 'BundleConsumerCardMessaging';

BundleConsumerCardMessaging.dataModel = extend(ConsumerCardMessaging);

export { BundleConsumerCardMessaging };