import React from 'react';
import { node } from 'prop-types';

const BuyboxContainer = ({ children }) => {
  return (
    <div
      // eslint-disable-next-line max-len
      className="sui-p-2 sui-bg-primary sui-rounded-base sui-w-full sui-mb-2 sui-mt-2 sui-overflow-visible empty:sui-hidden"
      data-component="BundleBuybox"
      data-testid="bundle-buybox"
    >
      {children}
    </div>
  );
};

BuyboxContainer.propTypes = {
  children: node.isRequired,
};

export default BuyboxContainer;
