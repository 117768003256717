import { useState, useEffect, useRef } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import { MAJOR_APPLIANCE_TYPE } from '../constants';

const getProduct = (productData, isMA) => {
  if (isMA && productData) {
    return {
      ...productData,
      bundleSpecificationDetails: {
        ...productData.bundleSpecificationDetails,
        type: MAJOR_APPLIANCE_TYPE
      }
    };
  }

  return productData || {};
};

export const useBundleProducts = (bundleId, storeId, listenToProductsChange = true, isMABundleEnabled) => {
  const [productIds, setProductIds] = useState(null);
  const [productsState, setProductsState] = useState(null);
  const productsRef = useRef([]);
  const swapProductRef = useRef(null);

  const swapProducts = (prevProductId, newProductId) => {
    const ids = productsRef.current.map((product) => product?.itemId);
    const i = ids.findIndex((id) => id === prevProductId);

    swapProductRef.current = {
      index: i,
      itemId: prevProductId
    };
    setProductIds([newProductId]);
  };

  const toggleProduct = (productId, isRemoved) => {
    let newProducts = [...productsRef.current];
    const i = productsRef.current.findIndex((product) => product.itemId === productId);
    newProducts[i] = { ...newProducts[i], removed: !isRemoved };
    productsRef.current = newProducts;
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('product-bundle.products-changed', productsRef.current);
  };

  useEffect(() => {
    if (!listenToProductsChange) return;
    LIFE_CYCLE_EVENT_BUS.lifeCycle.on('product-bundle.products-changed', ({ output }) => {
      setProductsState(output);
    });
  }, [listenToProductsChange]);

  const { data: productData, loading: productLoading, error: productError } = useDataModel('product', {
    variables: {
      itemId: bundleId,
      storeId
    },
    skip: !bundleId
  });

  const bundleProductIds = productData?.product?.bundleSpecificationDetails?.components;

  const { data, loading, error: productsError } = useDataModel('products', {
    variables: {
      itemIds: productIds || bundleProductIds?.map(({ defaultProductId }) => defaultProductId)
    },
    skip: !productIds && !bundleProductIds?.map(({ defaultProductId }) => defaultProductId)
  });

  useEffect(() => {
    if (data) {
      let newProductsData = productsRef.current.length > 0 ? [...productsRef.current] : data?.products;

      if (swapProductRef?.current !== null) {
        newProductsData[swapProductRef?.current.index] = data?.products?.[0];
        LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('product-bundle.products-changed', newProductsData);
        swapProductRef.current = null;
      }
      productsRef.current = newProductsData;
    }
  }, [data]);

  return {
    itemId: bundleId,
    product: getProduct(productData?.product, isMABundleEnabled),
    products: productsState !== null ? productsState : data?.products || [],
    loading: false, // productLoading || loading
    error: false, // productError || error
    productsError,
    swapProducts,
    toggleProduct
  };
};
