export const CARD_MEDIA_SM = '40px';
export const CARD_MEDIA_MD = '116px';
export const CARD_MEDIA_LG = '253px';
export const MAJOR_APPLIANCE_TYPE = 'configurable';
export const FREE_SHIPPING_THRESHOLD = 396;
export const TOOLTIP_VIEW_DETAILS = 'View Details';
export const TEXT = {
  FREE: 'Free',
  UNKNOWN: '-'
};
export const OUT_OF_STOCK = 'oos';
export const NOT_AVAILABLE = 'not_available';
export const SKU_NOT_VALID = 'SkuNumbers are not valid';
export const MIN_ADVERTISED_PRICE_MESSAGE = `We've set an item's price lower than the manufacturer
 will let us advertise, so you'll have to add it to your cart to see our price. If you decide
  you don't want to purchase the item, you can remove it from your cart.`;
export const CUSTOM_PRICE_VSO = 'Custom - Starting Price & Virtual Assist Only';