/* eslint-disable @mizdra/layout-shift/require-size-attributes */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useConfigService } from '@thd-nucleus/experience-context';
import classNames from 'classnames/bind';
import { bool } from 'prop-types';
import { MYCHECKOUT_SAVED_PAYPAL, SIGN_IN, NUCLEUS_CHECKOUT_SAVED_PAYPAL } from '../constants/constants';
import { savedPaypalButtonClickNewRelic } from '../utils/NewRelicHelper';
import styles from './paypal-checkout-button.module.scss';

const SavedPaypalButton = (props) => {
  const { payInFour, isValidCart, isAuthUser } = props;
  const isNucleusPaypalCheckout = useConfigService('isNucleusPaypalCheckout');

  const onClick = () => {
    try {
      savedPaypalButtonClickNewRelic(payInFour, isValidCart, isAuthUser);
      if (!isAuthUser) {
        window.top.location = window?.location?.origin + '/' + SIGN_IN;
      } else if (isValidCart) {
        const legacyCheckout = window?.location?.origin + MYCHECKOUT_SAVED_PAYPAL;
        const nulceusCheckout = window?.location?.origin + NUCLEUS_CHECKOUT_SAVED_PAYPAL;
        window.top.location = isNucleusPaypalCheckout ? nulceusCheckout : legacyCheckout;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  };

  return (
    <div>
      <button
        className={classNames(styles.savedPayPalButton)}
        type="button"
        onClick={onClick}
        data-testid="saved-paypal-button"
      >
        <div className={classNames(styles.savedPayPalButton__labelContainer)}>
          {!payInFour
            && (
              <>
                <div className={classNames(styles.savedPayPalButton__labelText)}>
                  Use My Saved
                </div>
                <img
                  className={classNames(styles.savedPayPalButton__labelImage)}
                  src="https://assets.homedepot-static.com/images/v1/paypal-logo-v3.svg"
                  role="presentation"
                />
              </>
            )}
          <img
            className={classNames(styles.savedPayPalButton__labelImage)}
            src="https://assets.homedepot-static.com/images/v1/paypal-logo-v2.svg"
            role="presentation"
          />
        </div>
      </button>
    </div>
  );
};

SavedPaypalButton.propTypes = {
  payInFour: bool.isRequired,
  isValidCart: bool.isRequired,
  isAuthUser: bool.isRequired,
};

SavedPaypalButton.displayName = 'SavedPaypalButton';

export { SavedPaypalButton };
