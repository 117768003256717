/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const CustomSkeletonLine = ({ height }) => {

  return (
    <span
      data-testid="custom-skeleton-line"
      className="sui-block sui-h-12 sui-bg-strong sui-mt-0 sui-mb-0 sui-leading-none motion-safe:sui-animate-pulse sui-w-full"
      style={{ height }}
    />
  );
};

CustomSkeletonLine.propTypes = {
  height: PropTypes.string,
};

CustomSkeletonLine.defaultProps = {
  height: '10px'
};

export { CustomSkeletonLine };
