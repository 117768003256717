import { useState, useEffect } from 'react';
import {
  getProductsFulfillmentInventories, getStateNameStateCode, getExcludedStatus
} from '../utils/product-bundle-utils';

const useGetExcludedShipState = ({ products, deliveryZip, storeZip }) => {
  const [excludedShipState, setExcludedShipState] = useState({ isExcluded: false, stateName: null });
  const [showCheckAvailability, setShowCheckAvailability] = useState(false);

  useEffect(() => {
    if (products && deliveryZip?.length > 0) {
      const productsFulfillmentInventories = getProductsFulfillmentInventories({
        products
      });
      const {
        deliveryStateName = '',
        deliveryStateCode = '',
        storeStateName = '',
        storeStateCode = ''
      } = getStateNameStateCode({ deliveryZip, storeZip });
      const excludedStatus = productsFulfillmentInventories?.reduce((excludeStateAccumulator, product) => {
        if (excludeStateAccumulator?.isExcluded) return excludeStateAccumulator;

        const bossExcluded = getExcludedStatus({ product, storeStateCode });
        const shipExcluded = getExcludedStatus({ product, deliveryStateCode });
        const isBopisNotFulfillableOOS = product?.inventory?.BOPIS === 0;
        if (bossExcluded?.status && shipExcluded?.status && isBopisNotFulfillableOOS) {
          return { ...excludeStateAccumulator, isExcluded: true };
        }

        return excludeStateAccumulator;
      }, { isExcluded: false, deliveryStateName, storeStateName, storeStateCode });

      setExcludedShipState(excludedStatus);
    }
  }, [products, deliveryZip]);

  return { excludedShipState, showCheckAvailability, setShowCheckAvailability };
};

export default useGetExcludedShipState;
