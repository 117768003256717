import fetch from 'node-fetch';
import {
  THANK_YOU_URL,
  SECURE_WWW_HOST,
  MYACCOUNT_SIGN_IN_URL,
  MY_CHECKOUT,
  getEnvProp,
  INSTANT_CHECKOUT_PATH,
  NEW_INSTANT_CHECKOUT_PATH,
} from '../constants/environment';
import {
  OPC_REDIRECT_ERROR_CODES,
  IC_SIGN_IN,
  CART_LEVEL_GENERIC_ERRORS,
} from '../constants/errorCodes';
import { publishNewRelicICClick, publishNewRelicICClickStatus } from './newRelicUtils';

export function getHostName() {
  if (
    typeof window !== 'undefined' &&
    window?.location &&
    window?.location?.origin?.includes('local') &&
    !window?.location?.href?.includes('/mycart/')
  ) {
    return window?.location?.origin;
  }
  return SECURE_WWW_HOST;
}

export function getThankYouURL(cartID) {
  const url = THANK_YOU_URL.replace('{host}', getHostName());
  if (cartID) {
    return url + '?cartId=' + cartID + '&icOrder=true';
  }
  return url;
}

function getNewParams() {
  const paramsObj = { icReferrer: window.location.pathname };
  const searchParams = new URLSearchParams(paramsObj);
  const searchStr = searchParams.toString();
  return searchStr ? '?' + searchStr : '';
}

export function getParams(enableNewInstantCheckoutUrl) {
  if (enableNewInstantCheckoutUrl) {
    return getNewParams();
  }

  let params = '';
  if (typeof window !== 'undefined' && window?.location && window?.location?.pathname) {
    params = window?.location?.pathname?.split('/')[1];
    return params ? '?icFrom=' + params : '';
  }
  return params;
}

export function getInstantCheckoutPath(enableNewInstantCheckoutUrl) {
  const params = getParams(enableNewInstantCheckoutUrl);
  return enableNewInstantCheckoutUrl === 1
    ? NEW_INSTANT_CHECKOUT_PATH + params
    : INSTANT_CHECKOUT_PATH + params;
}

export function getICprofileURL(enableNewInstantCheckoutUrl) {
  const icUrl = getHostName() + getInstantCheckoutPath(enableNewInstantCheckoutUrl);
  return icUrl;
}

export function getSignInUrl(redirect) {
  const url = getHostName() + MYACCOUNT_SIGN_IN_URL;
  if (redirect) {
    return url + '?redirect=' + redirect;
  }
  return url;
}

export function getCheckoutUrl() {
  return getHostName() + MY_CHECKOUT;
}

export function forwardToUrl(URL) {
  window.top.location = URL;
}

// moving this to cart-common-data once everything is finalized
function buildInstantCheckoutRequest(paymentId) {
  const IC_URL = getHostName() + '/mcc-cart/v1/order/submit';
  const options = {
    method: 'POST',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      // eslint-disable-next-line prettier/prettier
      Accept: 'application/json',
    },
    body: JSON.stringify({
      CartRequest: {
        paymentId,
      },
    }),
  };

  return new fetch.Request(IC_URL, options);
}

function encodeCookieValue(cookieValue) {
  return encodeURIComponent(cookieValue).replace(/\(/g, '%28').replace(/\)/g, '%29');
}

export function createBrowserCookie(
  cookieName,
  cookieValue,
  cookieExpirationInSeconds,
  path = '/',
  domain = null
) {
  const expDt = new Date();
  expDt.setTime(expDt.getTime() + cookieExpirationInSeconds * 1000);
  const expDtString = expDt === null ? '' : expDt.toUTCString();
  let cookieString = [
    cookieName + '=' + encodeCookieValue(cookieValue),
    ' expires=' + expDtString,
    ` path=${path}`,
  ].join(';');
  if (domain) {
    cookieString += '; domain=' + domain;
  }
  document.cookie = cookieString;
}

function isOpcRedirectErr(errObj) {
  return errObj?.some((error) => {
    if (OPC_REDIRECT_ERROR_CODES.includes(error.errorCode)) {
      publishNewRelicICClickStatus({
        status: 'fail',
        errorCode: error.errorCode,
      });
      createBrowserCookie('THD_ICO_ERR', error.errorCode, 86400, '/', getEnvProp('COOKIE_DOMAIN'));
      return true;
    }
    return false;
  });
}

export function hasGenericError(errorModel) {
  const cartError = errorModel.some((error) =>
    CART_LEVEL_GENERIC_ERRORS.includes(error.correlationType)
  );

  return cartError;
}

export function triggerInstantCheckoutButton(paymentId, submitCart, emailId) {
  const cartSubmit = {
    cartAttr: { communication: { emailId } },
    payments: [{ savedPaymentId: paymentId }],
  };
  try {
    publishNewRelicICClick();
    submitCart({
      experience: {
        type: 'Cart',
      },
      cartSubmit,
    })
      .then((response) => {
        try {
          const errObj = response?.errors?.[0]?.extensions?.errors;
          const errorCode =
            response?.errors?.[0]?.extensions?.errors?.[0]?.errorCode || 'unknown error code';
          const errorDesc =
            response?.errors?.[0]?.extensions?.errors?.[0]?.description || 'unknown error desc';
          const correlationType =
            response?.errors?.[0]?.extensions?.errors?.[0]?.correlationType || '';
          // On success forward to thank you page
          if (response?.data?.instantCheckoutCartSubmit?.cartId && !response?.errors) {
            window.LIFE_CYCLE_EVENT_BUS.trigger('instant-checkout.click', {});
            publishNewRelicICClickStatus({ status: 'pass' });
            forwardToUrl(getThankYouURL(response?.data?.instantCheckoutCartSubmit?.cartId));
          } else if (response?.errors && errObj.some((error) => error.errorCode === IC_SIGN_IN)) {
            publishNewRelicICClickStatus({
              status: 'fail',
              errorCode,
            });
            const redirect = window?.location?.href?.replace(window?.location?.origin, '');
            forwardToUrl(getSignInUrl(redirect));
          } else if (isOpcRedirectErr(errObj)) {
            forwardToUrl(getCheckoutUrl());
          } else if (hasGenericError(errObj)) {
            // Generic error shown on nucleus cart page
            const cartLevelError = errObj?.filter((error) =>
              CART_LEVEL_GENERIC_ERRORS.includes(error?.correlationType)
            );
            window.LIFE_CYCLE_EVENT_BUS.trigger('cart.alertsAndMessages', {
              id: 'INSTANT_CHECKOUT_ERROR',
              shortDesc: cartLevelError?.[0].description,
              type: cartLevelError?.[0].correlationType,
              correlationType: cartLevelError?.[0].correlationType,
            });
            window.LIFE_CYCLE_EVENT_BUS.trigger('instant-checkout.error', {
              code: cartLevelError?.[0].errorCode,
              message: cartLevelError?.[0].description,
              pageSection: 'cart',
            });
            publishNewRelicICClickStatus({
              status: 'fail',
              errorCode: cartLevelError?.[0].errorCode,
            });
          } else {
            window.LIFE_CYCLE_EVENT_BUS.trigger('cart.alertsAndMessages', {
              id: 'INSTANT_CHECKOUT_ERROR',
              shortDesc: errorDesc,
              type: 'error',
              correlationType,
            });
            window.LIFE_CYCLE_EVENT_BUS.trigger('instant-checkout.error', {
              code: errorCode,
              message: errorDesc,
              pageSection: 'cart',
            });
            publishNewRelicICClickStatus({
              status: 'fail',
              errorCode,
            });
          }
          return false;
        } catch (error) {
          /* eslint-disable-next-line no-console */
          console.log(error);
        }
        return false;
      })
      .catch((error) => {
        /* eslint-disable-next-line no-console */
        console.log(error);
      });
  } catch (error) {
    /* eslint-disable-next-line no-console */
    console.log(error);
  }
}
