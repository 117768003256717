import React, { useContext } from 'react';
import {
  number, string as propTypeString, shape as shapeProp,
} from 'prop-types';
import {
  params, string, arrayOf,
  extend, shape
} from '@thd-nucleus/data-sources';
import { SalientListItem, SalientPoints } from '@thd-olt-component-react/salient-points';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Link } from '@one-thd/sui-atomic-components';
import { getIsMobile, scrollTo, checkIfKitchenBundle } from '../utils/product-bundle-utils';
/**
 * Props
 *
 * @typedef {object} Props
 * @property {itemId} itemId Item Id
 * @property {max} max No of salient bullets
 * @property {stickyHeaderHeight} stickyHeaderHeight Height of the sticky header.
 * @property {productOverviewSection} productOverviewSection Id of the product overview section to scroll.
 */

/**
 * Returns a component, Salient poinst bullets
 * @param {Props} Props
 * @returns {React.ReactElement} JSX
 */

export const BundleSalientPoints = ({
  itemId, max, stickyHeaderHeight, productOverviewSection, product
}) => {

  const experienceContext = useContext(ExperienceContext);
  const { channel } = experienceContext;
  const isMobile = getIsMobile(channel);
  const isKitchenBundle = checkIfKitchenBundle(product);

  if (isKitchenBundle || isMobile) {
    return null;
  }

  const handleViewMoreDetails = () => {
    if (productOverviewSection) {
      const element = document.getElementById(productOverviewSection);
      const offset = element?.offsetTop - stickyHeaderHeight;
      scrollTo({ offset, accordionName: `#${productOverviewSection}` });
      if (productOverviewSection) {
        LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('product-bundle.open-accordion', `#${productOverviewSection}`);
      }
    }
  };
  return (
    <div data-component="BundleSalientPoints" data-testid="BundleSalientPoints">
      <SalientPoints itemId={itemId} max={max}>
        <SalientListItem>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            underline="always"
            onClick={handleViewMoreDetails}
          >
            View More Details
          </Link>
        </SalientListItem>
      </SalientPoints>
    </div>
  );
};

BundleSalientPoints.propTypes = {
  itemId: propTypeString.isRequired,
  max: number,
  stickyHeaderHeight: number,
  productOverviewSection: propTypeString.isRequired,
  product: shapeProp({}).isRequired,
};

BundleSalientPoints.defaultProps = {
  max: 3,
  stickyHeaderHeight: 0
};
BundleSalientPoints.dataModel = extend(
  {
    product: params({ itemId: string().isRequired() }).shape({
      details: shape({
        highlights: arrayOf(string())
      }),
    })
  },
  {
    metadata: params({ parentId: string().isRequired() }).shape({
      attributes: arrayOf(shape({
        attributeValues: arrayOf(shape({
          value: string()
        }))
      }))
    })
  },
  SalientPoints
);

BundleSalientPoints.displayName = 'BundleSalientPoints';