import React from 'react';
import PropTypes from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { WhatWeOffer } from '@thd-olt-component-react/what-we-offer';
import { Card } from '@one-thd/sui-atomic-components';

import { isMajorAppliances } from '../utils/product-bundle-utils';

/**
 * Props
 *
 * @typedef {object} Props
 * @property {string} itemId The identifier of the element
 * @property {bool} isForMA Whether the component is for MA
 */

/**
 * Returns a formated Product List of the provided data showing title and a related-table
 * @param {Props} Props
 * @returns {React.ReactElement} JSX
 */

const BundleWhatWeOffer = ({ itemId, product }) => {

  if (!itemId || !isMajorAppliances(product)) {
    return null;
  }

  return (
    <div data-component="BundleWhatWeOffer" data-testid="bundle-what-we-offer">
      <Card className="sui-border-0 sui-w-full">
        <WhatWeOffer itemId={itemId} />
      </Card>
    </div>
  );
};

BundleWhatWeOffer.propTypes = {
  itemId: PropTypes.string.isRequired,
  product: PropTypes.shape({}).isRequired
};

BundleWhatWeOffer.propTypes = {
  itemId: PropTypes.string.isRequired,
};

BundleWhatWeOffer.displayName = 'BundleWhatWeOffer';

BundleWhatWeOffer.dataModel = extend(WhatWeOffer);

export { BundleWhatWeOffer };
