import React from 'react';
import { Button } from '@thd-olt-component-react/button';
import { useConfigService } from '@thd-nucleus/experience-context';
import { bool, number, shape, string } from 'prop-types';
import { getInstantCheckoutPath } from '../../utils';
import { ENABLE_NEW_INSTANT_CHECKOUT } from '../../constants/features';

const FULFILLMENT_METHOD_STH = 'ShipToHome';

const InstantCheckoutEnabled = ({
  atcRequest,
  fulfillmentMethod,
  instantCheckoutModel,
  channel,
  disabled,
}) => {
  const enableNewInstantCheckoutDrawer = useConfigService('enableNewInstantCheckoutDrawer');
  const enableNewInstantCheckoutUrl = useConfigService(ENABLE_NEW_INSTANT_CHECKOUT);
  const instantCheckoutUrl = getInstantCheckoutPath(enableNewInstantCheckoutUrl);

  let host = 'https://www.homedepot.com';
  if (typeof window !== 'undefined') {
    const origin = window.location.origin;
    host = origin.match(/local/g) ? 'https://www.homedepot.com' : origin;
  }

  return (
    <div className="instant-checkout" data-component="InstantCheckoutEnabled">
      <div className="instant-checkout__wrapper">
        <div className="instant-checkout__box instant-checkout__ic-min">
          <div className="ic-button">
            <Button
              disabled={disabled}
              outline
              className="atc_instant_checkout"
              onClick={(event) => {
                window.LIFE_CYCLE_EVENT_BUS.trigger('cart.instant-checkout', {
                  cartReqParams: atcRequest,
                  cartOptions: {
                    host,
                    channel,
                    enableNewInstantCheckoutDrawer,
                  },
                });
              }}
            >
              Instant Checkout
            </Button>
          </div>
          {fulfillmentMethod === FULFILLMENT_METHOD_STH && (
            <div className="no-justify-center">
              <span>
                Deliver to:&nbsp;
                <a href={instantCheckoutUrl} className="u__default-link">
                  {instantCheckoutModel?.addressZipCode}
                </a>
                &nbsp;|&nbsp;
              </span>
              <span>
                <a href={instantCheckoutUrl} className="u__default-link">
                  {instantCheckoutModel?.displayCardBrand}&nbsp;&#8226;&#8226;
                  {instantCheckoutModel?.xref}
                </a>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

InstantCheckoutEnabled.propTypes = {
  channel: string,
  fulfillmentMethod: string,
  atcRequest: shape({
    itemId: string,
    quantity: number,
    fulfillmentMethod: string,
    fulfillmentLocation: string,
  }),
  instantCheckoutModel: shape({
    addressZipCode: string,
    displayCardBrand: string,
    xref: string,
  }),
  disabled: bool,
};

InstantCheckoutEnabled.defaultProps = {
  channel: 'desktop',
  fulfillmentMethod: null,
  instantCheckoutModel: null,
  atcRequest: null,
  disabled: false,
};
export { InstantCheckoutEnabled };
