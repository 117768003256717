import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  params, string, shape, number, client, extend, useDataModel
} from '@thd-nucleus/data-sources';
import { Button } from '@one-thd/sui-atomic-components';
import { AddToListDrawer } from './drawers/AddToListDrawer';
import { TRACK } from './constants';

const AddToList = ({
  itemId,
  quantity,
  CustomAddToListActionElement,
  products,
  skipListId,
  isSharedList,
  showCount
}) => {
  const [open, setOpen] = useState(false);
  const [favoriteCount, setFavoriteCount] = useState(0);

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('add-to-list.ready');
  }, []);

  const { data, variables } = useDataModel('product', {
    variables: {
      itemId
    },
    skip: (!itemId || !showCount)
  });
  const favoriteDetailCount = data?.product?.favoriteDetail?.count;

  useEffect(() => {
    setFavoriteCount(favoriteDetailCount);
  }, [favoriteDetailCount]);

  const count = favoriteCount || favoriteDetailCount;
  let favoriteCountRoundOff = count;
  const maxFavoriteCount = 999;
  if (count && count > maxFavoriteCount) {
    favoriteCountRoundOff = `${(count / 1000).toFixed(1)}k`;
  }

  const closeDrawer = () => {
    setOpen(!open);
  };

  const openDrawer = () => {
    setOpen(true);
    if (window.digitalData?.page?.pageInfo?.pageType === 'pip') {
      window.LIFE_CYCLE_EVENT_BUS.trigger(TRACK.BUTTON_CLICK, {});
    }
    LIFE_CYCLE_EVENT_BUS.trigger(TRACK.OPEN_DRAWER, {});
  };

  const favButtonWrapperClass = classNames('sui-bg-subtle sui-rounded-full', {
    'sui-pr-2': count
  });

  return (
    <span data-component="AddToList">
      {CustomAddToListActionElement
        ? (
          <>
            {showCount
              ? (
                <div className={favButtonWrapperClass}>
                  <CustomAddToListActionElement onClick={openDrawer} />
                  {count && (<span>{favoriteCountRoundOff}</span>)}
                </div>
              ) : (
                <CustomAddToListActionElement onClick={openDrawer} />
              )}
          </>
        ) : (
          <Button
            variant="secondary"
            fullWidth
            onClick={openDrawer}
          >
            Add to List
          </Button>
        )}
      {
        open && (
          <AddToListDrawer
            itemId={itemId}
            quantity={quantity}
            products={products}
            open={open}
            onClose={closeDrawer}
            skipListId={skipListId}
            isSharedList={isSharedList}
            setFavoriteCount={setFavoriteCount}
            favoriteCount={favoriteCount}
          />
        )
      }
    </span>
  );
};

AddToList.propTypes = {
  itemId: PropTypes.string,
  skipListId: PropTypes.string,
  isSharedList: PropTypes.bool,
  quantity: PropTypes.number,
  CustomAddToListActionElement: PropTypes.func,
  products: PropTypes.arrayOf(PropTypes.shape({
    itemId: PropTypes.string,
    quantity: PropTypes.number,
    price: PropTypes.number
  })),
  showCount: PropTypes.bool
};

AddToList.defaultProps = {
  itemId: null,
  skipListId: null,
  quantity: 1,
  CustomAddToListActionElement: null,
  products: [],
  isSharedList: false,
  showCount: null,
};

AddToList.displayName = 'AddToList';

const favoriteDetailCount = shape({
  count: number()
});

if (favoriteDetailCount.skip) {
  favoriteDetailCount.skip('skipFavoriteCount', false);
}

AddToList.dataModel = extend(
  {
    product: params({ itemId: string().isRequired(), dataSource: string() }).shape({
      itemId: string(),
      dataSources: string(),
      favoriteDetail: client(favoriteDetailCount)
    })
  },
  AddToListDrawer
);

export { AddToList };
