import { BundleAccordions as BundleAccordionsBase } from './components/BundleAccordions';
import { BundleBuybox as BundleBuyboxBase } from './components/BundleBuybox';
import {
  BundleConsumerCardMessaging as BundleConsumerCardMessagingBase
} from './components/BundleConsumerCardMessaging';
import { BundleFulfillment as BundleFulfillmentBase } from './components/BundleFulfillment';
import { BundleMediaGallery as BundleMediaGalleryBase } from './components/BundleMediaGallery';
import { BundlePageAnalytics as BundlePageAnalyticsBase } from './components/BundlePageAnalytics';
import { BundlePrice as BundlePriceBase } from './components/BundlePrice';
import { BundleProductDetails as BundleProductDetailsBase } from './components/BundleProductDetails';
import { BundleProductList as BundleProductListBase } from './components/BundleProductList';
import { BundleProductOverview as BundleProductOverviewBase } from './components/BundleProductOverview';
import { BundleSalientPoints as BundleSalientPointsBase } from './components/BundleSalientPoints';
import { BundleShare } from './components/BundleShare';
import { BundleSpecifications as BundleSpecificationsBase } from './components/BundleSpecifications';
import { BundleSponsoredCarousel as BundleSponsoredCarouselBase } from './components/BundleSponsoredCarousel';
import { BundleStickyNav as BundleStickyNavBase } from './components/BundleStickyNav';
import { BundleSummary as BundleSummaryBase } from './components/BundleSummary';
import { BundleWhatWeOffer as BundleWhatWeOfferBase } from './components/BundleWhatWeOffer';

import { useBundleProducts } from './hooks/useBundleProducts';
import { withBundleProducts } from './components/WithBundleProducts';

const BundleAccordions = withBundleProducts(BundleAccordionsBase);
const BundleBuybox = withBundleProducts(BundleBuyboxBase);
const BundleConsumerCardMessaging = withBundleProducts(BundleConsumerCardMessagingBase);
const BundleFulfillment = withBundleProducts(BundleFulfillmentBase);
const BundleMediaGallery = withBundleProducts(BundleMediaGalleryBase);
const BundlePageAnalytics = withBundleProducts(BundlePageAnalyticsBase);
const BundlePrice = withBundleProducts(BundlePriceBase);
const BundleProductDetails = withBundleProducts(BundleProductDetailsBase);
const BundleProductList = withBundleProducts(BundleProductListBase);
const BundleProductOverview = withBundleProducts(BundleProductOverviewBase);
const BundleSalientPoints = withBundleProducts(BundleSalientPointsBase);
const BundleSpecifications = withBundleProducts(BundleSpecificationsBase);
const BundleSponsoredCarousel = withBundleProducts(BundleSponsoredCarouselBase);
const BundleStickyNav = withBundleProducts(BundleStickyNavBase);
const BundleSummary = withBundleProducts(BundleSummaryBase);
const BundleWhatWeOffer = withBundleProducts(BundleWhatWeOfferBase);

export {
  BundleAccordions,
  BundleBuybox,
  BundleConsumerCardMessaging,
  BundleFulfillment,
  BundleMediaGallery,
  BundlePageAnalytics,
  BundlePrice,
  BundleProductDetails,
  BundleProductList,
  BundleProductOverview,
  BundleSalientPoints,
  BundleShare,
  BundleSpecifications,
  BundleSponsoredCarousel,
  BundleStickyNav,
  BundleSummary,
  BundleWhatWeOffer,
  useBundleProducts,
};
