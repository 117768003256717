import { useState, useEffect, useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { getPrice } from '../api/priceHandler';
import { getCartRequestInfo, adjustProductsPricing } from '../utils/product-bundle-utils';

const usePriceAdjustment = (products, isMajorAppliances) => {
  const experienceContext = useContext(ExperienceContext);
  const { hosts } = experienceContext;
  const { globalCustomConfiguratorsBaseUrl: priceAdjustmentUrl } = hosts;
  const [adjustedPricing, setAdjustedPricing] = useState(null);

  useEffect(() => {
    if (products?.length) {
      const getPriceAdjustment = async () => {
        const responseGetPrice = await getPrice({
          cartRequestInfo: getCartRequestInfo({ products }),
          priceAdjustmentUrl,
        });
        const adjustedProductsPricing = adjustProductsPricing(
          products,
          responseGetPrice?.cartResponse?.items,
        );

        setAdjustedPricing(adjustedProductsPricing);
      };

      getPriceAdjustment();
    }
  }, [products, isMajorAppliances]);

  return adjustedPricing;
};

export default usePriceAdjustment;
