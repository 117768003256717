import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import {
  extend, useDataModel, arrayOf, customType, params, string, shape
} from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  Drawer,
  DrawerHeader,
} from '@one-thd/sui-atomic-components';
import { AddToListForm } from './AddToListForm';
import { CreateNewList } from './CreateNewList';
import { SignInDrawer } from './SignInDrawer';

const customer = {
  svocId: null, userId: null, type: ''
};
const AddToListDrawer = ({
  itemId,
  open,
  onClose,
  quantity,
  products,
  skipListId,
  isSharedList,
  setFavoriteCount,
  favoriteCount
}) => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  const [showCreateList, setShowCreateList] = useState(false);
  const [isExistingLists, setIsExistingLists] = useState(false);
  const [signInDrawer, setSignInDrawer] = useState(false);
  const listDetailsFirstLoad = useRef(true);

  if (!customer.svocId) {
    if (typeof window !== 'undefined') {
      const { svocID, userID, customerType } = window.THDCustomer?.default;
      customer.svocId = svocID;
      customer.userId = userID;
      customer.type = customerType;
    }
  }

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('AddToListDrawer.ready');
  }, []);

  const { data, loading, refetch, error } = useDataModel('listDetails', {
    variables: {
      customerAccountID: customer.svocId,
      userId: customer.userId,
      customerType: customer.type?.toUpperCase()
    },
    fetchPolicy: 'cache-first',
    skip: (!customer.svocId || !customer.userId)
  });

  const { listDetails: listDetailsData = null } = data?.listDetails?.listResponse || {};

  const listDetails = (listDetailsData || []).filter((list) => list.listId !== skipListId);

  useEffect(() => {
    if (listDetailsFirstLoad.current && !loading && !signInDrawer) {
      listDetailsFirstLoad.current = false;
      setShowCreateList(!listDetails?.length);
    }
    if (!loading && listDetails.length !== 0) {
      setIsExistingLists(true);
    } else {
      setIsExistingLists(false);
    }
  }, [listDetails, loading, refetch]);

  const onCreateList = () => {
    refetch();
  };

  const drawerWrapperClass = className(
    'sui-flex', 'sui-flex-col',
    {
      'sui-h-screen': !isMobile,
      'portrait:sui-h-[calc(88vh)] landscape:sui-h-screen': isMobile
    }
  );

  useEffect(() => {
    if (!window.THDCustomer?.default?.isLoggedIn) {
      setSignInDrawer(true);
    }
  }, [open]);

  return (
    <Drawer open={open} onClose={onClose}>
      <div className={drawerWrapperClass} data-component="AddToListDrawer">
        <DrawerHeader
          title="Add to List"
          onClose={onClose}
        />
        {
          !signInDrawer && (
            showCreateList ? (
              <CreateNewList
                itemId={itemId}
                quantity={quantity}
                products={products}
                onClose={onClose}
                onCreate={onCreateList}
                isExistingLists={isExistingLists}
                isSharedList={isSharedList}
                setFavoriteCount={setFavoriteCount}
                favoriteCount={favoriteCount}
              />
            ) : (
              <AddToListForm
                itemId={itemId}
                quantity={quantity}
                products={products}
                listDetails={listDetails}
                shouldCreateList={setShowCreateList}
                onClose={onClose}
                isSharedList={isSharedList}
                setFavoriteCount={setFavoriteCount}
                favoriteCount={favoriteCount}
              />
            )
          )
        }
        {signInDrawer && (
          <SignInDrawer
            onClose={onClose}
          />
        )}
      </div>
    </Drawer>
  );
};

AddToListDrawer.propTypes = {
  itemId: PropTypes.string,
  skipListId: PropTypes.string,
  quantity: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isSharedList: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    itemId: PropTypes.string,
    quantity: PropTypes.number,
    price: PropTypes.number
  })),
  setFavoriteCount: PropTypes.func.isRequired,
  favoriteCount: PropTypes.number
};

AddToListDrawer.defaultProps = {
  itemId: null,
  skipListId: PropTypes.string,
  quantity: 1,
  products: [],
  favoriteCount: null
};

AddToListDrawer.displayName = 'AddToExistingList';

AddToListDrawer.dataModel = extend(
  {
    listDetails: params({
      customerAccountID: string().isRequired(),
      userId: string().isRequired(),
      customerType: customType('CustomerType').enum(['B2B', 'B2C'], 'B2C')
    }).shape({
      listResponse: shape({
        listDetails: arrayOf(shape({
          listId: string().isRequired(),
          listName: string()
        }))
      })
    })
  },
  AddToListForm,
  CreateNewList
);

export { AddToListDrawer };
