import React, { useState, useEffect } from 'react';
import { Heading } from '@thd-olt-component-react/heading';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { useLifeCycleEventBusLifeCycle } from '@thd-olt-functional/utils';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { ComponentPlaceholder } from '@thd-olt-component-react/loading-placeholder';
import DesktopContent from './components/Content/DesktopContent';
import { RichContent } from './components/RichContent/RichContent';
import {
  defaultThemeProperties,
  productOverviewDefaultProps, productOverviewPropTypes,
  themeProperties
} from './helpers';
import { dataModel } from './dataModel';

export const ProductOverviewDesktopComponent = (props) => {
  const {
    headingTitle,
    itemId,
    showRichContentsOnly,
    hideRichContents,
    preview,
    showReturnMessage,
    showPla,
    isUsingAccordion
  } = props;

  const { configuredItemId } = useLifeCycleEventBusLifeCycle('configurator.stock_product_chosen_lifecycle_event');

  if (showRichContentsOnly) {
    return (
      <RichContent
        itemId={itemId}
      />
    );
  }

  return (
    <div
      data-section="product-overview"
      data-testid="product-overview-desktop"
    >
      {!isUsingAccordion && <Heading title={headingTitle} underline />}
      <DesktopContent
        itemId={configuredItemId || itemId}
        hideRichContents={hideRichContents}
        preview={preview}
        showReturnMessage={showReturnMessage}
        showPla={showPla}
      />
    </div>
  );
};

ProductOverviewDesktopComponent.displayName = 'ProductOverviewDesktop';

ProductOverviewDesktopComponent.dataModel = dataModel;

ProductOverviewDesktopComponent.propTypes = productOverviewPropTypes;

ProductOverviewDesktopComponent.defaultProps = productOverviewDefaultProps;

ProductOverviewDesktopComponent.themeProps = themeProperties;

ProductOverviewDesktopComponent.defaultThemeProps = defaultThemeProperties;

ProductOverviewDesktopComponent.dataModel = dataModel;

const HydratedComponent = withHydrator({
  delay: 1500,
  id: 'product-section-overview',
  placeholder: (
    <ComponentPlaceholder
      height={2288}
      rows={20}
      color="#E0E0E0"
      showLoadingAnimation
    />
  ),
  scrollBuffer: 750,
}, ProductOverviewDesktopComponent);

const DynamicComponent = withDynamicComponent(HydratedComponent);
const ProductOverviewDesktop = withErrorBoundary(DynamicComponent);

ProductOverviewDesktop.displayName = 'ProductOverviewDesktop';

ProductOverviewDesktop.dataModel = dataModel;

ProductOverviewDesktop.propTypes = productOverviewPropTypes;

ProductOverviewDesktop.defaultProps = productOverviewDefaultProps;

ProductOverviewDesktop.themeProps = themeProperties;

ProductOverviewDesktop.defaultThemeProps = defaultThemeProperties;

ProductOverviewDesktop.dataModel = dataModel;

export { ProductOverviewDesktop };
