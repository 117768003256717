import React from 'react';
import { node, string, bool } from 'prop-types';

const MinAdvertisedPriceContainer = ({ maMinAdvertisedPriceExists, dashClass, isSubtotal, children }) => {
  if (maMinAdvertisedPriceExists) {
    if (isSubtotal) {
      return (
        <div className="sui-flex sui-flex-col">
          <span className={dashClass} data-testid="map-price-subtotal-dash" />
          <span className="sui-border-solid sui-border-b-1 sui-pb-3 sui-pl-4 sui-w-20" />
        </div>
      );
    }
    return (<span className={dashClass} data-testid="map-price-dash" />);
  }
  return (<>{children}</>);
};

MinAdvertisedPriceContainer.propTypes = {
  children: node.isRequired,
  maMinAdvertisedPriceExists: bool.isRequired,
  dashClass: string.isRequired,
  isSubtotal: bool
};

MinAdvertisedPriceContainer.defaultProps = {
  isSubtotal: false
};

export default MinAdvertisedPriceContainer;
