import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useFloating,
  offset,
  flip,
  shift,
  arrow
} from '@floating-ui/react-dom';
import classNames from 'classnames';
import { Typography } from '../typography/Typography';
import { IconButton } from '../button/IconButton';
import { callAllHandlers } from '../utils';
import { Close } from '../private/icons/Close';
import useForkRef from '../private/hooks/useForkRef';
import useId from '../private/hooks/useId';
import useWindowSize from '../private/hooks/useWidowSize';
import useEnhancedEffect from '../private/hooks/useEnhancedEffect';

const getArrowStyle = (placement, arrowData) => {
  const { x: arrowX, y: arrowY } = arrowData || {};
  const mainPlacement = placement.split('-')[0];
  const staticSide = { top: 'bottom', right: 'left', bottom: 'top', left: 'right' }[mainPlacement];
  // Avoid shadow overlapping by cutting off the excess
  // Coordinates are based on a 8px squared arrow
  const clipPath = {
    bottom: 'path(\'M18-10-10 18A1 1 0 0118-10\')',
    top: 'path(\'M18-10-10 18A1 1 0 0018-10\')',
    left: 'path(\'M18 18-10-10A1 1 0 0118 18\')',
    right: 'path(\'M18 18-10-10A1 1 0 0018 18\')',
  }[mainPlacement];
  return {
    left: arrowX != null ? `${arrowX}px` : '',
    top: arrowY != null ? `${arrowY}px` : '',
    right: '',
    bottom: '',
    [staticSide]: '-4px',
    clipPath
  };
};

const offsetSizes = {
  tight: -4,
  snug: 0,
  normal: 4,
  relaxed: 8,
  loose: 12,
};

/**
 * `Tooltip` is a floating container with a minimal amount of clarifying information (i.e. one short sentence).
 *
 * Related components: [Popover](#popover)
 *
 * Usage:
 *
 * ```jsx
 * import { Tooltip } from '@one-thd/sui-atomic-components';
 * ```
 */
const Tooltip = React.forwardRef((props, ref) => {

  const {
    placement = 'bottom',
    title,
    offset: offsetProp = 'loose',
    id,
    children,
    ...other
  } = props;

  const tooltipId = useId(id);

  const [show, setShow] = useState(false);
  const arrowRef = useRef(null);

  const offsetValue = offsetSizes[offsetProp];
  const [width, _height] = useWindowSize();
  const {
    x: left, y: top, reference, floating, strategy, placement: usedPlacement, middlewareData, update
  } = useFloating({
    placement,
    middleware: [offset(offsetValue), shift(), flip(), arrow({ element: arrowRef })],
  });

  useEnhancedEffect(() => {
    if (show) {
      update();
    }
  }, [show, update, width]);

  const arrowStyle = getArrowStyle(usedPlacement, middlewareData.arrow);
  const tooltipClasses = classNames('sui-min-w-[128px] sui-max-w-[182px] sui-pointer-events-none sui-flex sui-items-center sui-justify-between sui-bg-primary sui-z-30 sui-shadow-sm sui-rounded-md sui-p-3', {
    'sui-visible': show,
    'sui-invisible': !show
  });

  const close = () => {
    setShow(false);
  };

  const open = () => {
    setShow(true);
  };

  const keyDownHandler = (event) => {
    if (event.keyCode === 27 && show) { // Esc key
      event.stopPropagation();
      setShow(false);
    }
    if (event.keyCode === 13) { // Enter key
      setShow(true);
    }
  };

  const child = React.Children.only(children);
  const childProps = child.props;
  const trigger = React.cloneElement(child, {
    ref: reference,
    onMouseOver: callAllHandlers(childProps.onMouseOver, open),
    onFocus: callAllHandlers(childProps.onFocus, open),
    onMouseLeave: callAllHandlers(childProps.onMouseLeave, close),
    onKeyDown: callAllHandlers(childProps.onKeyDown, keyDownHandler),
    onBlur: callAllHandlers(childProps.onBlur, close),
    'aria-describedby': childProps['aria-describedby'] || tooltipId,
  });

  const hasTitle = (title || '').length > 0;

  const handleRef = useForkRef(floating, ref);

  return (
    <>
      {trigger}
      {hasTitle && (
        <div
          className={tooltipClasses}
          ref={handleRef}
          style={{
            position: strategy,
            top: top ?? 0,
            left: left ?? 0,
          }}
          {...other}
        >
          <Typography color="primary" variant="body-xs" role="tooltip" id={tooltipId}>{title}</Typography>
          <div className="[@media(any-hover:hover)]:sui-hidden sui-pointer-events-auto">
            <IconButton onClick={() => close()} icon={Close} aria-label="Close tooltip" />
          </div>
          <div
            ref={arrowRef}
            className="sui-absolute sui-w-2 sui-h-2 sui-rotate-45 sui-bg-primary sui-shadow-sm"
            style={arrowStyle}
          />
        </div>
      )}
    </>
  );
});

Tooltip.displayName = 'Tooltip';

Tooltip.propTypes = {
  /**
   * Only one element is expected as a child.
   * If you provide a custom component, make sure to spread its props and forward its ref
   */
  children: PropTypes.node.isRequired,
  /**
   * The text to show inside the Tooltip
   */
  title: PropTypes.string.isRequired,
  /**
   * Specify how far the tooltip should be from the bounding box of the trigger object
   */
  offset: PropTypes.oneOf([
    'tight',
    'snug',
    'normal',
    'relaxed',
    'loose',
  ]),
  /**
   * The prefered placement of the Tooltip component relative to the trigger object.
   * Note that the final placement could be different depending on available space and trigger location.
   */
  placement: PropTypes.oneOf([
    'bottom',
    'bottom-start',
    'bottom-end',
    'top',
    'top-start',
    'top-end',
    'left',
    'left-start',
    'left-end',
    'right',
    'right-start',
    'right-end'
  ]),
  /**
   * The id to be given to the Tooltip DOM element
   */
  id: PropTypes.string

};

Tooltip.defaultProps = {};

export { Tooltip };
